<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <!--   No data panel -->
  <div v-if="empty==true" class="mt-3" >
    <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
      <h4 class="mt-3">No data available.</h4>
    </div>
  </div>
  <!--  Photos-->
  <h4 v-if="games.length > 0">Game photos</h4>

  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-6 col-10" v-for="game in games" :key="game.id">
      <card >
        <img v-if="game.url && !game.overlay_url" slot="image" class="photo" :src="game.url"  alt="Card image cap"/>
        <img v-if="game.url && game.overlay_url" slot="image" class="photo" :src="game.overlay_url"  alt="Card image cap"/>
        <img v-if="!game.url" slot="image" class="photo" src="https://offthecouch.io/img/placeholder.jpg"  alt="Card image cap"/>
        <h4 class="card-title">
          <span class="text-success">{{game.game_name}}</span> at {{game.company_name}}</h4>
        <p class="text-white">{{getDate(game.booking_date)}}, {{getTime(game.start_time)}}</p>
      </card>
    </div>
  </div>

  <h4 v-if="user_photos.length > 0">Other photos</h4>
  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-6 col-10" v-for="(p, i) in user_photos" :key="'A' + i">
      <card >
        <img v-if="p.overlay_url" slot="image" class="photo" :src="p.overlay_url"  alt="Card image cap"/>
        <img v-else slot="image" class="photo" :src="p.url"  alt="Card image cap"/>
        <p style="font-size: medium" > Taken at
          <span class="text-success">{{p.company_name}}</span> on {{getDate(p.timestamp)}}
        </p>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {date, ampm} from "@/plugins/dateFormatter";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";

export default {
  name: "MyPhotos",
  data() {
    return {
      loading: false,
      empty: false,
      games: '',
      user_photos: ''
    }
  },
  methods: {
    getDate(d) {
      return date(d)
    },
    getTime(d) {
      return ampm(d)
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getPhotos() {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'photos'

      axios.get(url, config)
        .then(response => {
          this.loading = false
          //refresh the page
          axios.get(url, config)
            .then(response => {
              this.games = response.data.game_photos
              this.user_photos = response.data.user_photos
              if (this.games.length <= 0) {
                this.empty = true
              }
            })

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          }
        })
    },
  },
  mounted() {
    this.getPhotos()
  }
}
</script>

<style scoped>
.photo {
  width: 100%; /* width of container */
  height: 400px; /* height of container */
  object-fit: cover;
}

</style>
