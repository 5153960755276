<template>
  <div></div>
  <!--<footer class="footer">-->
    <!--<div class="container-fluid">-->
      <!--<ul class="nav">-->
        <!--<li class="nav-item">-->
          <!--<a-->
            <!--href="http://creative-tim.com"-->
            <!--target="_blank"-->
            <!--rel="noopener"-->
            <!--class="nav-link"-->
          <!--&gt;-->
            <!--Creative Tim-->
          <!--</a>-->
        <!--</li>-->
        <!--<li class="nav-item">-->
          <!--<a-->
            <!--href="https://www.creative-tim.com/presentation"-->
            <!--target="_blank"-->
            <!--rel="noopener"-->
            <!--class="nav-link"-->
          <!--&gt;-->
            <!--About Us-->
          <!--</a>-->
        <!--</li>-->
        <!--<li class="nav-item">-->
          <!--<a-->
            <!--href="http://blog.creative-tim.com"-->
            <!--target="_blank"-->
            <!--rel="noopener"-->
            <!--class="nav-link"-->
          <!--&gt;-->
            <!--Blog-->
          <!--</a>-->
        <!--</li>-->
      <!--</ul>-->
      <!--<div class="copyright">-->
        <!--&copy; {{ year }}, made with <i class="tim-icons icon-heart-2"></i> by-->
        <!--<a href="https://binarcode.com" rel="noopener" target="_blank">BinarCode</a> and-->
        <!--<a-->
          <!--href="https://www.creative-tim.com/?ref=pdf-vuejs"-->
          <!--target="_blank"-->
          <!--rel="noopener"-->
          <!--&gt;Creative Tim</a-->
        <!--&gt;-->
        <!--for a better web.-->
      <!--</div>-->
    <!--</div>-->
  <!--</footer>-->
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
