<template>
<div :style="{ 'background-color': survey_settings.background_color, 'overflow': 'auto',
  'position' : 'absolute',
  'top' : '0px',
    'right' : '0px',
  'bottom':'0px',
  'left':'0px', 'overflow-x': 'hidden'}">
  <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + survey_settings.font_family">
  <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + survey_settings.font_family_card">
  <loading-panel :loading="loading"></loading-panel>
  <not-found-page v-show="notFound"></not-found-page>
  <div class="row justify-content-center">
    <div class="col-lg-6 mt-5">
      <card :style="{ 'background-color': survey_settings.card_color}" style="padding-left: 30px; padding-right: 30px; padding-top:10px; padding-bottom: 5px">
        <h1 :style="{'font-family': survey_settings.font_family, 'color': survey_settings.text_color}">{{ survey_settings.name }}</h1>

        <!--        Customer fields-->
        <div v-if="survey_settings.include_customer_first_name ||
                  survey_settings.include_customer_last_name ||
                  survey_settings.include_customer_email ||
                    survey_settings.include_customer_phone">
          <div class="row">
            <div class="col-lg-6 col-12" v-if="survey_settings.include_customer_first_name">
              <label :style="{'font-family': survey_settings.font_family, 'color': survey_settings.text_color}">
                {{ $t('survey.firstName') }}<span v-if="survey_settings.require_customer_first_name" style="color: red">*</span>
              </label>
              <base-input :error="user.err_first_name" v-model="user.first_name" :input-style="{'font-family': survey_settings.font_family, 'border': '1px solid ' + survey_settings.text_color, 'color': survey_settings.text_color}"> </base-input>
            </div>
            <div class="col-lg-6 col-12" v-if="survey_settings.include_customer_last_name">
              <label :style="{'font-family': survey_settings.font_family, 'color': survey_settings.text_color}">
                {{ $t('survey.lastName') }}<span v-if="survey_settings.require_customer_last_name" style="color: red">*</span>
              </label>
              <base-input :error="user.err_last_name" v-model="user.last_name" :input-style="{'font-family': survey_settings.font_family, 'border': '1px solid ' + survey_settings.text_color, 'color': survey_settings.text_color}"> </base-input>
            </div>
          </div>
          <div class="row" >
            <div class="col-lg-6 col-12" v-if="survey_settings.include_customer_email">
              <label :style="{'font-family': survey_settings.font_family, 'color': survey_settings.text_color}">
                {{ $t('survey.email') }}<span v-if="survey_settings.require_customer_email" style="color: red">*</span>
              </label>
              <base-input :error="user.err_email" v-model="user.email" :input-style="{'font-family': survey_settings.font_family, 'border': '1px solid ' + survey_settings.text_color, 'color': survey_settings.text_color}"> </base-input>
            </div>
            <div class="col-lg-6 col-12" v-if="survey_settings.include_customer_phone">
              <label :style="{'font-family': survey_settings.font_family, 'color': survey_settings.text_color}">
                {{ $t('survey.phone') }}<span v-if="survey_settings.require_customer_phone" style="color: red">*</span>
              </label>
              <base-input :error="user.err_phone" v-model="user.phone" :input-style="{'font-family': survey_settings.font_family, 'border': '1px solid ' + survey_settings.text_color, 'color': survey_settings.text_color}"> </base-input>
            </div>
          </div>
          <hr :style="{'border': '0.5px solid ' + survey_settings.text_color}">
        </div>
        <div class="row mb-3" v-for="(q, i) in questions" :key="'Q' + i">
          <div class="col">
<!--            Checkboxes-->
            <p style="font-size: medium" :style="{'font-family': survey_settings.font_family, 'color': survey_settings.text_color}">
              {{q.question}}<span v-if="q.required" style="color: red">*</span>
            </p>
            <div v-if="q.response_field_type === 1">

              <base-input :error="q.error">
                <div class="row" v-for="(co, i) in q.checkbox_options" :key="'CO2' + i">
                  <div class="col">
                    <base-checkbox :error="q.error"
                                   :border-color="survey_settings.button_color" :check-mark-color="survey_settings.card_color"
                                   :accent-color="survey_settings.button_color"
                                   v-model="co.selected">
                      <p :style="{'font-family': survey_settings.font_family, 'color': survey_settings.text_color}">
                        {{co.label}}
                      </p>
                    </base-checkbox>
                  </div>
                </div>
              </base-input>
            </div>
            <div v-if="q.response_field_type === 2">
              <base-input :error="q.error" v-model="q.response" :input-style="{'border': '1px solid ' + survey_settings.text_color,
              'font-family': survey_settings.font_family,
              'color': survey_settings.text_color}"></base-input>
            </div>
            <div v-if="q.response_field_type === 3">
              <div class="row" >
                <div class="col-lg-6">
                  <base-input :error="q.error">
                    <base-dropdown :key="j" style="color: #00bbff" :style="{'font-family': survey_settings.font_family }"
                                   title-classes="form-group form-control" :text-color="survey_settings.text_color"
                                   :font-family="survey_settings.font_family"
                                   :title="q.selectedDropdown"
                    >
                      <a v-for="(d, i) in q.dropdown_options"  :style="{'font-family': survey_settings.font_family }"
                         style="white-space: normal; overflow-wrap: break-word;"
                         class="dropdown-item" href="#" :key="'D' + i" v-on:click="selectDropdown(q, d)">
                        {{ d.label }}</a>
                    </base-dropdown>
                  </base-input>
                </div>
              </div>
            </div>
            <div v-if="q.response_field_type === 4">
              <div class="row" v-for="(ro, i) in q.rating_options" :key="'RO2' + i">
                <div class="col-lg-4">
                  <base-input >
                    <b-form-rating size="lg" v-model="q.response"
                                   :icon-empty="ro.icon"
                                   :icon-full="ro.icon + '-fill'"
                                   :color="ro.color" style="cursor: pointer"
                                   no-border
                                   :stars="ro.count">
                    </b-form-rating>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">

            <base-button simple v-on:click="submitSurvey()" :disabled="prevent_submit"
              :style="{ 'font-family': survey_settings.font_family,
              'border-color': survey_settings.button_color,
              'color': survey_settings.button_color }">{{ $t('survey.submit') }}</base-button>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import NotFoundPage from "../../pages/GeneralViews/NotFoundPage";
import BaseRadio from "@/components/Inputs/BaseRadio";
import {BFormRating, BIcon} from "bootstrap-vue";
import BaseDropdown from 'src/components/BaseDropdown.vue';
import swal from "sweetalert2";

export default {
  name: "Survey",
  components: {BaseRadio,
    NotFoundPage,
    BFormRating,
    BaseDropdown,
    BIcon},
  data(){
    return {
      loading: false,
      notFound: false,
      prevent_submit: false,
      survey_settings: '',
      questions: [],
      hash: '',
      j:0,
      user: {
        first_name: '',
        err_first_name: '',
        last_name: '',
        err_last_name: '',
        email: '',
        err_email: '',
        phone: '',
        err_phone: '',
      }
    }
  },
  methods: {
    isEmailValid(emailString) {
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(emailString);
    },
    invalidForm(){
      let errors = 0

      //user
      if(this.survey_settings.require_customer_first_name && this.user.first_name === ''){
        this.user.err_first_name = this.$t('survey.errors.firstName')
        errors++
      } else {
        this.user.err_first_name = ''
      }

      if(this.survey_settings.require_customer_last_name && this.user.last_name === ''){
        this.user.err_last_name = this.$t('survey.errors.lastName')
        errors++
      } else {
        this.user.err_last_name = ''
      }

      if(this.survey_settings.require_customer_email &&
        !this.isEmailValid(this.user.email)){
        this.user.err_email = this.$t('survey.errors.email')
        errors++
      } else {
        this.user.err_email = ''
      }

      if(this.survey_settings.require_customer_phone && this.user.phone === ''){
        this.user.err_phone = this.$t('survey.errors.phone')
        errors++
      } else {
        this.user.err_phone = ''
      }

      for(let n in this.questions){
        if(this.questions[n].required && this.questions[n].response === ''){

          this.questions[n].error = this.$t('survey.errors.required')
          errors++
        } else {
          this.questions[n].error = ''
        }
      }

      return errors > 0 ? true : false
    },
    async submitSurvey(){
      //checkbox responses
      for(let n in this.questions){
        if(this.questions[n].response_field_type === 1){
          this.questions[n].response = []
          for(let m in this.questions[n].checkbox_options){
            if(this.questions[n].checkbox_options[m].selected){
              this.questions[n].response.push(this.questions[n].checkbox_options[m].label)
            }
          }
          this.questions[n].response = JSON.stringify(this.questions[n].response)
        }
        if(this.questions[n].response_field_type === 4 && this.questions[n].response === ''){
          this.questions[n].response = 0
        }
      }
      //validate
      if(this.invalidForm()){
        return
      }

      let url = API_LOCATION + 'surveys/' + this.hash
      let data = {
        questions: this.questions,
        user: this.user,
        waiver_id: this.$route.query.id
      }

      this.prevent_submit = true
      axios.post(url, data)
        .then(response => {
          swal(this.$t('survey.swals.success'), this.$t('survey.swals.surveySubmitted'), 'success').then((response)=> {
            window.location.href = this.survey_settings.redirect_url
          }).then(response => {
            this.prevent_submit = false
          })
        })
        .catch(err => {
          console.log(err)
          swal( this.$t('survey.swals.error'), this.$t('survey.swals.somethingWentWrong'),'error')
        })

    },
    selectDropdown(question, option){
      question.response = option.label
      question.selectedDropdown = option.label
      this.j++
    },
    getSurvey(){
      let url = API_LOCATION + 'surveys/' + this.hash
      axios.get(url)
        .then(response => {
          this.survey_settings = response.data.survey_settings
          this.questions = response.data.questions
          for(let n in this.questions){
            if(this.questions[n].checkbox_options){
              this.questions[n].checkbox_options = _.orderBy(JSON.parse(this.questions[n].checkbox_options), 'id')
            }
            if(this.questions[n].rating_options){
              this.questions[n].rating_options = JSON.parse(this.questions[n].rating_options)
            }
            if(this.questions[n].dropdown_options){
              this.questions[n].selectedDropdown = this.$t('survey.selectOption')
              this.questions[n].dropdown_options = _.orderBy(JSON.parse(this.questions[n].dropdown_options), 'id')
            }
          }
        })
        .catch(err => {
          this.notFound = true
          console.log(err)
        })
    }
  },
  mounted() {
    this.hash = this.$route.params.hash
    this.getSurvey()
  }
}
</script>

<style scoped>

</style>
