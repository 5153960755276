<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>

<!--    Game details-->
    <div class="row">
      <div class="col-lg-12">
        <card class="card-subcategories" card-body-classes="table-full-width">
          <img slot="image" class="card-img-bottom fixed-size-img" :src="game.url"  alt="Card image cap"/>
          <h3 slot="header" class="card-title">{{game.game_name}} - {{getDate(game.booking_date)}}
          </h3>

          <div class="row">
            <div class="col-lg-3">
              <card class="card-stats card-white" :show-footer-line="true">
                <div class="row">
                  <div class="col-3">
                    <div class="info-icon text-center">
                      <i class="tim-icons icon-calendar-60"></i>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="numbers">
                      <slot>
                        <p class="card-category">Booking Time</p>
                        <h3 class="card-title">{{getAMPM(game.booking_time)}}</h3>
                      </slot>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-lg-3">
              <card class="card-stats card-white" :show-footer-line="true">
                <div class="row">
                  <div class="col-3">
                    <div class="info-icon text-center">
                      <i class="tim-icons icon-triangle-right-17"></i>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="numbers">
                      <slot>
                        <p class="card-category">Start Time</p>
                        <h3 class="card-title">{{getAMPM(game.start_time)}}</h3>
                      </slot>
                    </div>
                  </div>
                </div>
              </card>
            </div>


            <div class="col-lg-3">
              <card class="card-stats card-white" :show-footer-line="true">
                <div class="row">
                  <div class="col-3">
                    <div class="info-icon text-center">
                      <i class="tim-icons icon-trophy"></i>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="numbers">
                      <slot>
                        <p class="card-category">End Time</p>
                        <h3 class="card-title">{{getAMPM(game.end_time)}}</h3>
                      </slot>
                    </div>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-lg-3">
              <card class="card-stats card-white" :show-footer-line="true">
                <div class="row">
                  <div class="col-3">
                    <div class="info-icon text-center">
                      <i class="tim-icons icon-time-alarm"></i>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="numbers">
                      <slot>
                        <p class="card-category">Total Time</p>
                        <h3 class="card-title">{{getCompletionTime(game.start_time, game.end_time)}}</h3>
                      </slot>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <h4>Game completed &nbsp;
                <span v-if="game.completed" class="text-success">
                 <i class="text-success fas fa-check"></i>
                </span>
                <span v-if="!game.completed" class="text-danger">
                 <i class="fas fa-times"></i>
                </span>
              </h4>
              <h4>Group size &nbsp;
                <span class="text-success">
                <strong>{{players.length}}</strong>
              </span>
              </h4>
<!--              <h4>Clues received &nbsp;-->
<!--                <span class="text-success">-->
<!--                <strong>{{hints}}</strong>-->
<!--              </span>-->
<!--              </h4>-->
<!--              <h4 v-if="puzzles.length > 0">Percent completion &nbsp;-->
<!--                <span class="text-success">-->
<!--                <strong>{{getPercentCompletion(puzzles)}}</strong>-->
<!--              </span>-->

<!--              </h4>-->
<!--              <h4 v-if="!exceed_time">Time remaining &nbsp;-->
<!--                <span  class="text-success">-->
<!--                 <strong>{{getMinutesRemaining(gameInstance)}}</strong>-->
<!--              </span>-->
<!--              </h4>-->
<!--              <h4 v-if="exceed_time">Extra time spent  &nbsp;-->
<!--                <span  class="text-danger">-->
<!--                 <strong>{{getMinutesRemaining(gameInstance)}}</strong>-->
<!--              </span>-->
<!--              </h4>-->

            </div>
<!--            <div class="col-lg-3">-->
<!--              <h4>Group size &nbsp;-->
<!--                <span class="text-success">-->
<!--                <strong>{{players.length}}</strong>-->
<!--              </span>-->
<!--              </h4>-->
<!--              <h4>Clues received &nbsp;-->
<!--                <span class="text-success">-->
<!--                <strong>{{hints}}</strong>-->
<!--              </span>-->
<!--              </h4>-->
<!--            </div>-->
          </div>
        </card>
      </div>
    </div>

<!--    Puzzles-->
    <div class="row" v-if="puzzles.length > 0">
      <div class="col-lg-12">
        <card card-body-classes="table-full-width">
          <div class="row">
            <div class="col">
              <h4  slot="header" class="card-title">Puzzles</h4>
            </div>
          </div>

          <!--Medium and large screens-->
          <div class="row d-none d-sm-block">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table striped hover :items="puzzles"
                       :fields="fields">
                <template v-slot:cell(puzzle_name)="row">
                  <span class="text-warning">{{row.item.puzzle_name}}</span>
                </template>
                <template v-slot:cell(solve_time)="row">
                  <span v-show="row.item.is_completed == 1">{{getAMPM(row.item.solve_time)}}</span>
                  <span v-show="row.item.is_completed == 0">-</span>
                </template>
                <template v-slot:cell(time_since_start)="row">
                  <span>{{getMinutesSinceStart(row.item)}}</span>
                </template>
                <template v-slot:cell(hint_number)="row">
                  <span>{{row.item.hints_given_count}}</span>
                </template>
                <template v-slot:cell(hints_given)="row">
                  <span>{{row.item.hints_given_description}}</span>
                </template>
              </b-table>
            </div>
          </div>
          <!--Mobile-->
          <div class="row mt-3 d-block d-sm-none">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table striped hover :items="puzzles"
                       :fields="mobile_fields">
                <template v-slot:cell(puzzles)="row">
                  <div class="row">
                    <div class="col-12">
                      <span class="text-warning">{{row.item.puzzle_name}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      Completed? &nbsp;
                      <i v-if="row.item.is_completed" class="tim-icons icon-check-2 text-success"></i>
                      <i v-if="!row.item.is_completed" class="tim-icons icon-simple-remove text-danger"></i>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      Solve time: &nbsp;
                      <span v-show="row.item.is_completed == 1">{{getTime(row.item.solve_time)}}</span>
                      <span v-show="row.item.is_completed == 0">-</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      Time since start: &nbsp;
                      <span>{{getMinutesSinceStart(row.item)}}</span>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </card>
      </div>
    </div>
    <!--    Photos-->
    <div class="row">
      <div class="col-lg-12">
        <card card-body-classes="table-full-width">
          <div class="row">
            <div class="col">
              <h4 slot="header" class="card-title">Photos and team
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <img class="photo-size" v-if="photo.length > 0" :src="photo[0].url"/>
              <div v-if="photo.length <= 0" class="mt-3" >
                <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
                  <h4 class="mt-3">No photos were taken for this game.</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div>
                <h5 v-if="game.team_name">
                  Team name: &nbsp; <badge type="primary">{{game.team_name}}</badge>
                </h5>
                <b-table v-if="players.length > 0" striped hover :items="players"
                         :fields="teamFields">
                  <template v-slot:cell(team)="row">
                    <div class="row">
                      <div class="col-lg-6">
                        <span class="text-warning">{{row.item.first_name}} {{row.item.last_name}}</span>
                        <p v-if="row.item.is_minor == 0" class="small">{{row.item.email}}</p>
                        <badge class="ml-2" type="primary" v-if="row.item.is_minor == 1">Minor</badge>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

  </div>

</template>

<script>
import {ampm, formattedDate, time} from 'src/plugins/dateFormatter'
import { BTable } from 'bootstrap-vue'
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Auth} from "aws-amplify";
import badge from "src/components/Badge"



export default {
  name: "PastGameDetails",
  components: {
    BTable,
    badge
  },
  data() {
    return {
      loading: false,
      empty: false,
      gameInstanceId: '',
      game: '',
      hints: 0,
      photo: [],
      players: [],
      puzzles: [],
      fields: ['puzzle_name', 'solve_time', 'time_since_start'],
      mobile_fields: ['puzzles'],
      teamFields: ['team']
    }
  },
  methods:{
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    getAMPM(d){
      return ampm(d)
    },
    getDate(d){
      return formattedDate(d)
    },
    getTime(d){
      return time(d)
    },
    getMinutesSinceStart(d){
      var start = new Date(this.game.start_time)
      var time = new Date(d.solve_time)
      var diffTime = (time - start)
      var total_minutes = parseInt(Math.floor(diffTime / (1000 * 60)));
      if (d.solve_time != null && d.is_completed == 1 && total_minutes >= 0) {
        return total_minutes + ' min'
      }
      return '-'
    },
    getPercentCompletion(puzzles){
      let completed = 0
      let total = puzzles.length
      let total_completion = 0

      for (let n in puzzles){
        if(puzzles[n].solve_time != null && puzzles[n].is_completed == 1){
          completed++
        }
      }
      if(total <=0){
        return 0 + '%'
      }
      total_completion = Math.floor((completed / total) * 100)

      return total_completion + '%'

    },
    getCompletionTime(start, end){
      let end_time = new Date(end)
      let start_time = new Date(start)
      let diffTime = (end_time - start_time)
      let total_minutes = parseInt(Math.floor(diffTime / (1000 * 60)));
      return total_minutes + ' min'
    },
    async getGameDetails(){
      this.gameInstanceId = localStorage.getItem('gameInstanceId')

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'games/' + this.gameInstanceId

      axios.get(url, config)
        .then(response => {
          this.players = response.data.players
          this.game = response.data.game
          this.puzzles = response.data.puzzles
          this.photo = response.data.photo
          this.hints = response.data.hints

          this.loading = false

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    }
  },
  mounted() {
    this.getGameDetails()
  }
}
</script>

<style scoped>
.photo-size{
  width: 100%; /* width of container */
  height: 500px; /* height of container */
  object-fit: cover;
}
.fixed-size-img {
  width: 100%; /* width of container */
  height: 150px; /* height of container */
  object-fit: cover;
}
</style>
