import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { DatePicker} from 'element-ui'
import fr from 'element-ui/lib/locale/lang/fr'
import es from 'element-ui/lib/locale/lang/es'
import en from 'element-ui/lib/locale/lang/es'
import locale from 'element-ui/lib/locale'

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([a-z0-9]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function detectBrowserLocale() {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') {
    return 'en';
  }
  const browserLocale = navigator.language || navigator.userLanguage || '';
  const languageCode = browserLocale.split('-')[0];

  const supportedLocales = ['en', 'fr', 'es'];
  if(languageCode === 'fr'){
    locale.use(fr)
  } else if(languageCode === 'es'){
    locale.use(es)
  }
  Vue.use(DatePicker)
  if (supportedLocales.includes(languageCode)) {
    return languageCode;
  }
  return 'en'
}

// export default new VueI18n({
//   locale: detectBrowserLocale(),
//   fallbackLocale: detectBrowserLocale(),
//   messages: loadLocaleMessages()
// });

const i18n = new VueI18n({
  locale: detectBrowserLocale(),
  fallbackLocale: detectBrowserLocale(),
  messages: loadLocaleMessages()
});

export default i18n;
