<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <div class="row justify-content-center">
      <div class="col-lg-3 col-md-4 col-sm-6 col-10" v-for="company in companies" :key="company.company_group_id">
        <card >
          <img slot="image" class="card-img-top fixed-size-img" :src="company.img"  alt="Card image cap"/>
          <h4 class="card-title">{{company.company}}: {{company.company_group}}</h4>
          <p class="text-muted">{{company.address}}</p>
          <p class="text-muted">{{company.city}}, {{company.state}} {{company.zip}}</p>

          <div class="row mt-3 justify-content-center">
            <div class="col-lg-12 ">
              <base-button simple size="lg" class="game-button" v-on:click="goToCompanyWaiver(company.code)"
                           type="success"> Select
               </base-button>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>

</template>

<script>
import { API_LOCATION } from 'src/api-config'
import NotFoundPage from "../../pages/GeneralViews/NotFoundPage";
import {Auth} from "aws-amplify";

export default {
  name: "SelectCompany",
  data() {
    return {
      companies: '',
      loading: false,
    }
  },
  methods: {
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    goToCompanyWaiver(code){
      localStorage.setItem('checkin', 'auth')
      this.$router.push('/checkin/' + code)
    },
    async getCompanies() {
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'companies'

      this.axios.get(url, config)
        .then(response => {
          this.loading = false
          this.companies = response.data

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })

    }
  },
  mounted() {
    this.getCompanies()
  }
}
</script>

<style scoped>
.game-button{
  width: 100%;
}

</style>
