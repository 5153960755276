<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
<!--    <sidebar-fixed-toggle-button />-->
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="title"
      :logo="logo"
    >
      <template slot-scope="props" slot="links">

        <sidebar-item
          :link="{
            name: $t('sidebar.future_games'),
            icon: 'tim-icons icon-calendar-60',
            path: '/upcoming-games'
          }"
        >
        </sidebar-item>

<!--        <sidebar-item-->
<!--          :link="{-->
<!--            name: $t('sidebar.games'),-->
<!--            icon: 'tim-icons icon-puzzle-10',-->
<!--            path: '/games'-->
<!--          }"-->
<!--        >-->
<!--        </sidebar-item>-->
        <sidebar-item
          :link="{
            name: $t('sidebar.games'),
            icon: 'tim-icons icon-puzzle-10',
            path: '/games'
          }"
        >
        </sidebar-item>
<!--        <sidebar-item-->
<!--          :link="{-->
<!--            name: $t('sidebar.leaderboards'),-->
<!--            icon: 'tim-icons icon-trophy',-->
<!--            path: '/games'-->
<!--          }"-->
<!--        >-->
<!--        </sidebar-item>-->
        <sidebar-item
          :link="{
            name: $t('sidebar.photos'),
            icon: 'tim-icons icon-camera-18',
            path: '/photos'
          }"
        >
        </sidebar-item>

<!--        <sidebar-item-->
<!--          :link="{-->
<!--            name: $t('sidebar.offers'),-->
<!--            icon: 'tim-icons icon-gift-2',-->
<!--            path: '/offers'-->
<!--          }"-->
<!--        >-->
<!--        </sidebar-item>-->

        <!--<sidebar-item v-show="hasPermissions(2)"-->
          <!--:link="{ name: $t('sidebar.surveys'), icon: 'tim-icons icon-pencil', path: '/surveys' }"-->
        <!--&gt;-->
          <!--<sidebar-item-->
            <!--:link="{ name: $t('sidebar.surveyListings'), path: '/surveys' }"-->
          <!--&gt;</sidebar-item>-->
        <!--</sidebar-item>-->


        <sidebar-item
          :link="{
            name: $t('sidebar.myProfile'),
            icon: 'tim-icons icon-settings-gear-63',
            path: '/settings'
          }"
        >
        </sidebar-item>

      </template>
    </side-bar>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import SidebarShare from './SidebarSharePlugin';
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare
  },
  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary,
      title: '',
      logo: ''
    };
  },
  methods: {
    setTitle(){
      this.logo = JSON.parse(localStorage.getItem('user')).img
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  },
  mounted() {
    this.setTitle();
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}


/* total width */
body::-webkit-scrollbar {
  background-color: rgba(50, 50, 93, 0.24);
  width: 7px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: rgba(50, 50, 93, 0.14);
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: rgba(218, 18, 18, 0.05);
  border-radius: 16px;
  border: 4px solid rgba(50, 50, 93, 0.79);
}
</style>
