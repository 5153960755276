<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <!--   No data panel -->
  <div v-if="empty==true" class="mt-3" >
    <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
      <h4 class="mt-3">No data available.</h4>
    </div>
  </div>
<!--  Games-->
  <div class="row">
    <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="game in games" :key="game.id">
      <card >
        <div slot="image" class="card-img-top" style="padding-bottom: 0; height: 200px">
          <el-carousel :arrow="getArrowSetting(JSON.parse(game.game_images))" :autoplay="false" indicator-position="none"  :interval="5000">
            <el-carousel-item style="border-radius: 5px; width: 100%; height: 200px;"
                              :style="{'background-color': item.background_color}" v-for="(item, index) in JSON.parse(game.game_images)"
                              :key="'CS' + index">
              <div  style="border-radius: 5px; width: 100%; height: 200px; position: relative">
                <img
                  :style="{'transform': 'translate(0%, 0%) scale(' + item.scale*0.01 + ')', 'left': item.x + 'px', 'top': item.y + 'px', 'object-fit':  item.object_fit, 'height': '100%'}"
                  class="card-img"
                  style="position: absolute; transform-origin: center center; will-change: transform"
                  slot="image" :src="item.url"  alt="Card image cap"/>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <h4 class="card-title">
          <span class="text-success">{{game.game_name}}</span> at {{game.company_name}}</h4>
        <p class="text-white">{{getDate(game.booking_date)}},
          {{getAMPM(game.booking_time)}}
        </p>
        <p class="text-white" v-if="game.completed == 1">
          <i class="text-warning tim-icons icon-trophy"></i> {{getCompletionTime(game.start_time, game.end_time)}} &nbsp;
          <i v-if="game.team_name" class="text-warning tim-icons el-icon-user"></i> {{game.team_name}}
        </p>
        <div class="row mt-2">
          <div class="col">
            <base-button v-on:click="viewDetails(game)"  type="success" simple size="sm">Game details</base-button>
            <base-button v-on:click="leaderboard(game)" class="ml-2" type="warning" simple size="sm">Leaderboard</base-button>

          </div>
         </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {formattedDate, time, ampm, date} from 'src/plugins/dateFormatter'
import {Carousel, CarouselItem} from "element-ui";

export default {
  name: "PastGames",
  data() {
    return {
      loading: false,
      empty: false,
      games: ''
    }
  },
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },
  methods: {
    getArrowSetting(images){
      if(!images){
        return ''
      }
      if(images.length <= 1){
        return 'never'
      }
      return "hover"
    },
    getAMPM(d){
      return ampm(d)
    },
    getDate(d){
      return date(d)
    },
    getTime(d){
      return time(d)
    },
    getCompletionTime(start, end){
      let end_time = new Date(end)
      let start_time = new Date(start)
      let diffTime = (end_time - start_time)
      let total_minutes = parseInt(Math.floor(diffTime / (1000 * 60)));
      return total_minutes + ' min'
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getPastGames(){
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'games'

      axios.get(url, config)
        .then(response => {
          this.loading = false
          //refresh the page
          axios.get(url, config)
            .then(response => {
              this.games = response.data
              if(this.games.length <= 0 ){
                this.empty = true
              }
            })

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    viewDetails(game){
      localStorage.setItem('gameInstanceId', JSON.stringify(game.id))
      this.$router.push('/game-details')
    },
    leaderboard(game){
      localStorage.setItem('gameName', JSON.stringify(game.game_name))
      localStorage.setItem('gameId', JSON.stringify(game.game_id))
      localStorage.setItem('gameInstanceId', JSON.stringify(game.id))
      this.$router.push('/leaderboard')
    }
  },
  mounted() {
    this.getPastGames()
  }
}
</script>

<style scoped>
.fixed-size-img {
  width: 100%; /* width of container */
  height: 150px; /* height of container */
  object-fit: cover;
}
</style>
