<template>
<div :style="{ 'background-color': bss.background_color, 'overflow': 'auto',
  'position' : 'absolute',
  'top' : '0px',
    'right' : '0px',
  'bottom':'0px',
  'left':'0px', 'overflow-x': 'hidden'}">
  <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + bss.font_family">
  <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + bss.font_family_card">
  <loading-panel :loading="loading"></loading-panel>
  <not-found-page v-show="notFound"></not-found-page>

  <div v-if="is_paid_in_full">
    <div class="container mt-5">
      <div class="row justify-content-between">
        <div class="col-9">
          <h3 :style="{'font-family': bss.font_family, 'color': bss.text_color}" class="card-title">
            {{ $t('payment_request.thankYouForPayment') }}
          </h3>
          <p :style="{'font-family': bss.font_family, 'color': bss.text_color}">
            {{ $t('payment_request.thankYouDescription') }}
          </p>
        </div>
        <div class="col text-right">
          <p :style="{'font-family': bss.font_family, 'color': bss.text_color}">{{bss.company_name}}</p>
          <p :style="{'font-family': bss.font_family, 'color': bss.text_color}">{{ $t('payment_request.transaction') }} #{{transaction.order_number}}</p>
        </div>
      </div>
    </div>

    <div class="container mt-3">
      <card :style="{ 'background-color': bss.card_color}">
        <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">{{ $t('payment_request.yourPurchases') }}
        </h4>
        <div class="row mt-5" v-for="(b, i) in bookings" :key="i + 'A'">
          <div style="width: 80px; margin-left: 20px;">
            <img :src="b.url"/>
          </div>
          <div class="col">
            <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{b.name}}</h4>
            <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">
              <i class="tim-icons icon-calendar-60"></i> {{getDate(b.booking_date)}}
              <i class="tim-icons el-icon-alarm-clock"></i> {{getTime(b.start_time)}}</p>
            <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">
              {{ $t('payment_request.groupSize') }}: {{b.group_size}}</p>
          </div>
        </div>
        <div class="row" v-for="(gc, index) in gift_cards" :key="'GC' + index">
          <div class="col-3">
            <img class="game-img" style="object-fit: contain" :src="gc.design_url"/>
          </div>
          <div class="col-9">
            <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">{{ $t('payment_request.giftCard') }}
            </p>
            <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{ $t('payment_request.quantity') }}:</span> 1</p>
            <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{ $t('payment_request.giftCardValue') }}:</span> {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(gc.total))}} {{getCurrencyCode(locale.currency)}}</p>
          </div>
        </div>
        <div class="row mt-2" v-for="(item, index) in items" :key="'ITEM' + index">
          <div class="col-3">
            <img class="game-img" :src="item.url"/>
          </div>
          <div class="col-9">
            <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">{{item.name}}
            </p>
            <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{ $t('payment_request.quantity') }}:</span> {{item.quantity}}</p>
            <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{ $t('payment_request.price') }}:</span> {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(item.amount))}} {{getCurrencyCode(locale.currency)}}</p>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col">
            <base-button :disabled="loading"
                         :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color }"
                         v-on:click="goHome()" type="primary" simple >
              {{ $t('payment_request.visitOurWebsite') }}</base-button>
            <base-button class="ml-2"
                         :disabled="loading"
                         :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color }"
                         v-on:click="goToWaiver()" type="primary" simple >
              {{ $t('payment_request.checkIn') }}</base-button>
          </div>
        </div>
      </card>
    </div>
  </div>
  <div v-if="!is_paid_in_full">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-9">
          <h3 :style="{'font-family': bss.font_family, 'color': bss.text_color}" class="card-title">{{ $t('payment_request.addPayment') }}
          </h3>
        </div>
        <div class="col text-right">
          <p :style="{'font-family': bss.font_family, 'color': bss.text_color}">{{bss.company_name}}</p>
          <p :style="{'font-family': bss.font_family, 'color': bss.text_color}">{{ $t('payment_request.transaction') }} #{{transaction.order_number}}</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <card :style="{ 'background-color': bss.card_color}">
          <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">
            {{ $t('payment_request.yourInformation') }}
          </h4>
          <div class="row">
            <div :style="{ 'color': bss.text_color_card}" class="col-12 col-lg-6">
              <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.firstName') }}</label>
              <base-input :secondary-color="bss.text_color_card" :error="errs.first_name"
                          :input-style="{'color': bss.text_color_card}" :placeholder="$t('payment_request.firstName')" v-model="user.first_name"></base-input>
            </div>
            <div :style="{ 'color': bss.text_color_card}" class="col-12 col-lg-6">
              <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.lastName') }}</label>
              <base-input :secondary-color="bss.text_color_card" :error="errs.last_name" :input-style="{'color': bss.text_color_card}" :placeholder="$t('payment_request.lastName')" v-model="user.last_name"></base-input>
            </div>
          </div>
          <div :style="{ 'color': bss.text_color_card}" class="row">
            <div class="col-12 col-lg-6">
              <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.email') }}</label>
              <base-input :secondary-color="bss.text_color_card" :error="errs.email" :input-style="{'color': bss.text_color_card}" placeholder="example@gmail.com" v-model="user.email" type="email"></base-input>
            </div>
            <div class="col-12 col-lg-6">
              <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.phone') }}</label>
              <base-input :secondary-color="bss.text_color_card" :error="errs.phone" :input-style="{'color': bss.text_color_card}"
                          :placeholder="country.placeholder_phone" v-model="user.phone"></base-input>
            </div>
          </div>
        </card>

        <card :style="{ 'background-color': bss.card_color}" v-if="custom_fields.length > 0">
          <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">
            {{ $t('payment_request.additionalInformation') }}
          </h4>
          <div :style="{ 'color': bss.text_color_card}" class="row" v-for="(cf, i) in custom_fields" :key="'CF' + i">
            <div class="col mb-3" v-if="cf.type === 1 && isFieldVisibile(cf)">
              <base-checkbox v-on:input="handleCustomFieldInput(cf)" :key="j"
                             :border-color="bss.button_color" :accent-color="bss.button_color"
                             :check-mark-color="bss.card_color"
                v-model="cf.customer_response"
                simple size="sm">
                <span :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{cf.label}}</span>
                <span v-for="(el, i) in cf.external_links" :key="'EL'+ i">
                  <a v-on:click="recordInteraction(el)" :style="{'font-family': bss.font_family_card, 'color': bss.link_color}" target="_blank" :href="el.link"> {{el.link_text}}</a>
                </span>
                <span v-for="(p, i) in cf.policies" :key="'PLI' + i">
                  <base-button :style="{'font-family': bss.font_family_card, 'color': bss.link_color}"
                               style="font-weight:300; font-size: small; padding: 0"
                                       size="sm" link class="btn btn-link" href="" v-on:click="openPolicy(p, cf)">{{p.policy_link_text}}
                  </base-button>
                </span>
                <span :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" v-if="cf.required">*</span>
              </base-checkbox>
              <p :style="{'font-family': bss.font_family_card, 'color': '#ec250d'}" class="ml-4" v-if="cf.error">
                {{ cf.error }}</p>
            </div>
            <modal :show.sync="modals.policy"
                   body-classes="p-0"
                   modal-classes="modal-dialog-centered modal-lg">
              <card :style="{ 'background-color': bss.background_color}"
                    header-classes="bg-white pb-5"
                    class="border-0 mb-0">

                <p :style="{'color': bss.text_color}" v-html="selectedPolicy.policy"></p>
                <div class="row mt-3 justify-content-center">
                  <div class="col text-center">
                    <base-button
                      :style="{ 'width': '100%', 'font-family': bss.font_family_card,
                            'border-color': bss.text_color, 'color': bss.text_color }" simple
                      v-on:click="agreeToPolicy()">{{ $t('payment_request.agree') }}</base-button>
                  </div>
                </div>
              </card>
            </modal>
            <div class="col mb-3" v-if="cf.type === 2 && isFieldVisibile(cf)">
              <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{cf.label}}<span v-if="cf.required">*</span>
              </label>
              <base-input :secondary-color="bss.text_color_card" :error="cf.error" :input-style="{'color': bss.text_color_card}"
                          v-model="cf.customer_response">

              </base-input>
            </div>
            <div class="col mb-3" v-if="cf.type === 3 && isFieldVisibile(cf)">
              <div class="row">
                <div class="col-lg-6">
                  <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{cf.label}}<span v-if="cf.required">*</span>
                  </label>
                  <base-input :secondary-color="bss.text_color_card" :placeholder="$t('payment_request.select')">
                    <base-dropdown :key="'CFF' + j" :style="{'font-family': bss.font_family_card }"
                                   title-classes="form-group form-control" :text-color="bss.button_color"
                                   :font-family="bss.font_family_card"
                                   :title="getDropDownLabel(cf.customer_response)"
                    >
                      <a v-for="(c, i) in cf.dropdown_options"  :style="{'font-family': bss.font_family_card }"
                         style="white-space: normal; overflow-wrap: break-word;"
                         class="dropdown-item" href="#" :key="'DOJ' + i" v-on:click="selectDropdown(cf, c)">
                        {{ getDropDownLabel(c) }}</a>
                    </base-dropdown>
                  </base-input>
                </div>
                <div class="col" v-if="cf.customer_response">
                  <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{cf.customer_response.follow_up_field_label}}
                    <span v-if="cf.required && cf.customer_response.has_follow_up_field">*</span>
                  </label>
                  <base-input :secondary-color="bss.text_color_card" :input-style="{'color': bss.text_color_card}" v-model="cf.customer_response.follow_up_response"
                              v-if="cf.customer_response && cf.customer_response.has_follow_up_field"></base-input>
                </div>
              </div>
            </div>
          </div>
        </card>

        <card :style="{ 'background-color': bss.card_color}">
          <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">
            {{ $t('payment_request.paymentOptions') }}
          </h4>
          <div class="row">
            <div class="col-lg-6">
              <base-input :secondary-color="bss.text_color_card" :placeholder="$t('payment_request.select')">
                <base-dropdown  :style="{'font-family': bss.font_family_card }"
                                title-classes="form-group form-control" :text-color="bss.button_color"
                                :font-family="bss.font_family_card"
                                :title="payment_option_title"
                >
                  <a :style="{'font-family': bss.font_family_card }" style="white-space: normal; overflow-wrap: break-word;"
                     class="dropdown-item" href="#"  v-on:click="updatePaymentAmount('full')">
                    {{ $t('payment_request.payFullAmount') }} -
                    {{getCurrencySymbol(locale.currency)}}{{getAmount(transaction.due)}}{{getCurrencyCode(locale.currency)}}</a>
                  <a  v-if="deposit > 0" :style="{'font-family': bss.font_family_card }" style="white-space: normal; overflow-wrap: break-word;"
                     class="dropdown-item" href="#"  v-on:click="updatePaymentAmount('deposit')">
                    {{ $t('payment_request.payDeposit') }} -
                    {{getCurrencySymbol(locale.currency)}}{{getAmount(deposit)}} {{getCurrencyCode(locale.currency)}}
                  </a>
                  <a v-if="payment_settings.payment_request_allow_custom_amounts" :style="{'font-family': bss.font_family_card }" style="white-space: normal; overflow-wrap: break-word;"
                      class="dropdown-item" href="#"  v-on:click="updatePaymentAmount('partial')">
                    {{ $t('payment_request.payCustomAmount') }}
                  </a>
                </base-dropdown>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label v-show="payment_option === 'partial'"
                     :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.amount') }} ({{getCurrencySymbol(locale.currency)}})</label>
              <base-input :input-style="{'color': bss.text_color_card}"
                          :secondary-color="bss.text_color_card"
              :error="errs.amount" v-on:focusout="updatePaymentAmount('partial')" placeholder="0"
              v-show="payment_option === 'partial'" v-model.number="partial_amount"></base-input>
            </div>
          </div>
        </card>
        <card :style="{ 'background-color': bss.card_color}" v-show="transaction.due > 0">
          <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">
            {{ $t('payment_request.paymentMethod') }}
          </h4>
          <div class="row">
            <div class="col-lg-6">
              <base-input :secondary-color="bss.text_color_card" :placeholder="$t('payment_request.select')">
                <base-dropdown  :style="{'font-family': bss.font_family_card }"
                                title-classes="form-group form-control" :text-color="bss.button_color"
                                :font-family="bss.font_family_card"
                                :title="selectedPaymentOption.display_name"
                >
                  <a v-for="(pm, i) in payment_methods" :key="'PM' + i" :style="{'font-family': bss.font_family_card }" style="white-space: normal; overflow-wrap: break-word;"
                     class="dropdown-item" href="#"  v-on:click="setPaymentOption(pm)">
                    {{ pm.display_name }}</a>
                </base-dropdown>
              </base-input>
            </div>
          </div>

          <div v-if="selectedPaymentOption.name !== 'Card' && selectedPaymentOption.name !== 'Gift card'">
            <div class="row">
              <div class="col">
                <span v-html="selectedPaymentOption.content"></span>
              </div>
            </div>
          </div>

          <div v-show="selectedPaymentOption.name === 'Card'">
            <!--      Authorize.net-->
            <div class="row mb-2" v-show="payment_settings.payment_gateway_id === 1">
              <div class="col-12 col-lg-5">
                <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.creditCardNumber') }}</label>
                <base-input :secondary-color="bss.text_color_card" :error="errs.cnn" :input-style="{'color': bss.text_color_card}" v-mask="'#### #### #### ####'" autocomplete="cc-number" placeholder="4444 4444 4444 4444" v-model="payment.cardNumber"></base-input>
              </div>
              <div class="col-12 col-lg-3">
                <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.expiration') }}</label>
                <base-input :secondary-color="bss.text_color_card" :error="errs.expiration" :input-style="{'color': bss.text_color_card}" v-mask="'##/##'" placeholder="11/27" v-model="payment.expirationDate"></base-input>
              </div>
              <div class="col-12 col-lg-2">
                <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.cvv') }}</label>
                <base-input :secondary-color="bss.text_color_card" :error="errs.cvv" :input-style="{'color': bss.text_color_card}" v-mask="'####'" placeholder="111" v-model="payment.cardCode" type="tel"></base-input>
              </div>
              <div class="col-12 col-lg-2">
                <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{country.zip_label}}</label>
                <base-input :secondary-color="bss.text_color_card" :error="errs.zip" :input-style="{'color': bss.text_color_card}"
                            :placeholder="country.zip_placeholder" v-model="payment.zip" type="text"></base-input>
              </div>
            </div>


            <div class="row" v-show="payment_settings.payment_gateway_id === 2">
              <div class="col">
                <!--                  Square-->
                <form id="payment-form-square">
                  <div id="card-container"></div>
                </form>
                <div id="payment-status-container"></div>
              </div>
            </div>

            <!--        Stripe-->
            <div class="row" v-show="payment_settings.payment_gateway_id === 3">
              <div class="col">
                <form id="payment-form">
                  <div id="payment-element">
                    <!--Stripe.js injects the Payment Element-->
                  </div>
                  <div id="payment-message" class="hidden"></div>
                </form>
              </div>
            </div>
          </div>

          <div class="row" v-if="selectedPaymentOption.name === 'Gift card'">
            <div class="col">
              <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.giftCardCode') }}</label>
              <base-input :secondary-color="bss.text_color_card" :input-style="{'color': bss.text_color_card}"
                :placeholder="$t('payment_request.giftCardCode')"  v-model="gift_card_code" :error="errs.gift_card_code">
              </base-input>
            </div>
          </div>

          <div class="row" v-if="payment_settings.payment_gateway_id === 5 && selectedPaymentOption.name === 'Yappy'">
            <div class="col">
              <base-button style="width: 100%" v-on:click="payWithYappy()"
                           simple :style="{ 'font-family': bss.font_family_card, 'border-color':
                                    bss.button_color, 'color': bss.button_color }">
                {{ $t('payment_request.payWith') }} <img class="ml-2" src="https://pagosbg.bgeneral.com/assets/img/yappy-logo-brand.svg">
              </base-button>
            </div>
          </div>

          <div class="row" v-show="selectedPaymentOption.name === 'Gift card' || selectedPaymentOption.name === 'Card'">
            <div class="col">
              <base-button :disabled="disable_submit"
                           :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color }"
                           v-on:click="createPayment()" type="primary" simple >
                {{ $t('payment_request.submitPayment') }}</base-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-lg-3 col">
        <card :style="{ 'background-color': bss.card_color }">
          <div class="row">
            <div class="col-11">
              <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">{{ $t('payment_request.yourPurchases') }}
              </h4>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="row" v-for="(c, index) in bookings" :key="'M' + index">
                <div class="col-3">
                  <img class="game-img" :src="c.url"/>
                </div>
                <div class="col-9">
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">{{c.name}}
                  </p>
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{ $t('payment_request.date') }}:</span> {{getDayOfWeek(c.booking_date)}} {{getFormattedDate(locale.date_format, c.booking_date)}}</p>
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{ $t('payment_request.time') }}:</span> {{getTime(c.start_time)}}</p>
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{ $t('payment_request.quantity') }}:</span> {{c.group_size}}</p>
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{ $t('payment_request.bookingSubtotal') }}:</span> {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(c.subtotal))}} {{getCurrencyCode(locale.currency)}}</p>
                </div>
              </div>

              <div class="row" v-for="(gc, index) in gift_cards" :key="'GC' + index">
                <div class="col-3">
                  <img class="game-img" style="object-fit: contain" :src="gc.design_url"/>
                </div>
                <div class="col-9">
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">{{ $t('payment_request.giftCard') }}
                  </p>
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{ $t('payment_request.quantity') }}:</span> 1</p>
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{ $t('payment_request.giftCardValue') }}:</span> {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(gc.total))}} {{getCurrencyCode(locale.currency)}}</p>
                </div>
              </div>
              <div class="row mt-2" v-for="(item, index) in items" :key="'ITEM' + index">
                <div class="col-3">
                  <img class="game-img" :src="item.url"/>
                </div>
                <div class="col-9">
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">{{item.name}}
                  </p>
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{ $t('payment_request.quantity') }}:</span> {{item.quantity}}</p>
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{ $t('payment_request.price') }}:</span> {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(item.amount))}} {{getCurrencyCode(locale.currency)}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3" v-if="bss.show_promo_code_field === 1">
            <div class="col">
              <div style="display: flex; width: 100%;">
                <base-input :secondary-color="bss.text_color_card" v-model="promo_code"
                            style="flex-grow: 1; border-radius: 0px;"
                            :input-style="{'border-color': bss.button_color, 'color': bss.text_color_card,
                              'border-radius': '5px 0px 0px 5px'}"
                            :placeholder="bss.promo_code_placeholder_text">
                </base-input>
                <base-button simple style="flex-grow: 0; border-radius: 0px 5px 5px 0px; border-left: none;"
                             :style="{ 'font-family': bss.font_family_card,
                         'background-color': bss.card_color,
                         'margin-left': '0px', 'margin-top': '0px', 'margin-bottom': '10px',
                         'padding-top': '0px', 'padding-bottom': '0px',
                         'padding-left': '10px', 'padding-right': '10px',
                         'border-color': bss.button_color, 'color': bss.button_color }"
                             v-on:click="applyPromo()">
                  {{ $t('payment_request.apply') }}
                </base-button>
              </div>
            </div>
          </div>
          <hr :style="{ 'background-color': bss.text_color_card}">
          <div class="row">
            <div class="col">
              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.subtotal') }}</p>
            </div>
            <div class="col" >
              <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getAmount(transaction.price)}} {{getCurrencyCode(locale.currency)}}</p>
            </div>
          </div>
          <div class="row" v-for="(t, i) in taxes" :key="'T' + i">
            <div class="col-6">
              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ t.name }}</p>
            </div>
            <div class="col">
              <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(t.amount))}} {{getCurrencyCode(locale.currency)}}</p>
            </div>
          </div>

          <div class="row" v-for="(f, i) in fees" :key="'K' + i">
            <div class="col-6">
              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ f.name }}</p>
            </div>
            <div class="col">
              <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(f.amount, f.value_type))}} {{getCurrencyCode(locale.currency)}}</p>
            </div>
          </div>

          <div class="row" v-for="(tips, i) in tips" :key="'TIPS' + i">
            <div class="col-6">
              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.tip') }}
              </p>
            </div>
            <div class="col">
              <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(tips.amount))}} {{getCurrencyCode(locale.currency)}}</p>
            </div>
          </div>

          <div class="row" v-for="(p, i) in promo_codes" :key="pc_key + i">
            <div class="col-6">
              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ p.name }}
              </p>
            </div>
            <div class="col">
              <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">-{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(p.amount))}} {{getCurrencyCode(locale.currency)}}</p>
            </div>
          </div>


          <hr :style="{ 'background-color': bss.text_color_card}">
          <div class="row">
            <div class="col">
              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.total') }}</p>
            </div>
            <div class="col" >
              <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(transaction.total))}} {{getCurrencyCode(locale.currency)}}</p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card, 'font-weight': 'bold'}">{{ $t('payment_request.amountDue') }}</p>
            </div>
            <div class="col" >
              <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card, 'font-weight': 'bold'}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(transaction.due))}} {{getCurrencyCode(locale.currency)}}</p>
            </div>
          </div>

          <div class="row" v-if="bookings.length > 0 && deposit > 0">
            <div class="col">
              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('payment_request.depositDue') }}</p>
            </div>
            <div class="col" >
              <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getAmount(deposit)}} {{getCurrencyCode(locale.currency)}}</p>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import BaseRadio from "@/components/Inputs/BaseRadio";
import _ from "lodash";
import currencies from "@/assets/currencies.json";
import countries from "@/assets/countries.json";
import NotFoundPage from "../../pages/GeneralViews/NotFoundPage";
import swal from "sweetalert2";
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";

export default {
  name: "PaymentPage",
  components: {BaseRadio, NotFoundPage},
  data(){
    return {
      selectedCf: '',
      payment_option_title: '',
      payment_methods: [],
      selectedPaymentOption: '',
      require_deposit: false,
      payment_intent_id: '',
      disable_submit: false,
      selectedPolicy: '',
      modals: { policy: false },
      custom_fields: [],
      promo_code: '',
      promo_codes: [],
      pc_key: 0,
      time_format: '',
      date_format: '',
      is_paid_in_full: false,
      currencies: currencies.currencies,
      loading: false,
      notFound: false,
      hash: '',
      transaction: '',
      bss: '',
      apply_promo: false,
      bookings: [],
      taxes: [],
      fees: [],
      items: [],
      tips: [],
      gift_cards: [],
      payment_settings: '',
      locale: '',
      country: '',
      countries: countries.countries,
      payment_option: 'full',
      partial_amount: 0,
      gift_card_code: '',
      errs: {
        gift_card_code: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        terms: '',
        ccn: '',
        zip: '',
        expiration: '',
        cvv: '',
        amount: ''
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      },
      payment: {
        amount: '',
        cardNumber: '',
        expirationDate: '',
        cardCode: '',
        zip: '',
        cardholder_first_name: '',
        cardholder_last_name: '',
      },
      card: '',
      elements: '',
      stripe: '',
      square: '',
      squareAppId: '',
      squareLocationId: '',
      deposit: 0,
      j: 0
    }
  },
  methods: {
    agreeToPolicy(){
      this.recordInteraction(this.selectedPolicy)
      if(this.handleCustomFieldInput(this.selectedCf)){
        this.selectedCf.customer_response = true
      }
      this.modals.policy = false
    },
    handleCustomFieldInput(field){
      let ready = false
      if(field.interaction_required){
        let total_field_count = 0
        let interactive_count = 0
        let links = field.external_links

        for(let n in links){
          total_field_count++
          if(links[n].user_interaction){
            interactive_count++
          }
        }

        let policies = field.policies
        for(let n in policies){
          total_field_count++
          if(policies[n].user_interaction){
            interactive_count++
          }
        }
        if(total_field_count === interactive_count){
          ready = true
          field.error = ""
        } else {
          ready = false
          field.customer_response = false
          field.error = "Please read all attached policies"
        }
        this.j++
      } else {
        ready = true
      }
      return ready
    },
    recordInteraction(item){
      item.user_interaction = 1
    },
    isFieldVisibile(field){
      if(field.visibility === 1 && (field.visibility_conditions || field.show_when_gift_card_in_cart)){
        let allowed_games = JSON.parse(field.visibility_conditions)

        let found = 0
        let found_row = ''
        for(let n in this.bookings){
          found_row = _.find(allowed_games, {id: this.bookings[n].game_id})
          if(found_row) {
            found++
          }
        }
        if(this.gift_cards.length > 0 && field.show_when_gift_card_in_cart){
          found++
        }
        if(found <= 0){
          return false
        }
      }
      return true
    },
    setPaymentOption(option){
      if(option.name !== 'Card'){
        if(this.payment_settings.payment_gateway_id === 3){
          this.disable_submit = false
        }
      } else {
        if(this.payment_settings.payment_gateway_id === 3){
          this.disable_submit = true
        }
        if(this.payment_settings.payment_gateway_id === 2){
          this.initializeSquare()
        }
      }
      this.selectedPaymentOption = option
    },
    getDropDownLabel(item){
      if(item){
        return item.name
      }
      return ''
    },
    selectDropdown(question, option){
      question.customer_response = option
      this.j++
    },
    openPolicy(item, field){
      this.selectedCf = field
      this.selectedPolicy = item
      this.modals.policy = true
    },
    getTotalAmount(){
      return this.transaction.due
    },
    getTax(amount, value_type, is_compound, apply_after_discount){
      let taxable_subtotal = 0
      let total_number_of_players = 0
      for(let n in this.cart){
        if(!this.cart[n].hasOwnProperty('gift_card')){
          taxable_subtotal += parseFloat(this.cart[n].subtotal)
          total_number_of_players += this.cart[n].quantity
        }
      }
      if(value_type === 0){
        return amount
      }
      if(value_type === 1){
        return amount * total_number_of_players
      }
      if(value_type === 2 && !is_compound){
        if(apply_after_discount === 1){
          let after_discount = taxable_subtotal - this.getDiscounts()
          if(after_discount <= 0){
            amount = 0
          }
          return this.getPercentage(amount, after_discount)
        } else {
          return this.getPercentage(amount, taxable_subtotal)
        }
      }
      if(value_type === 2 && is_compound){
        if(apply_after_discount === 1){
          let after_discount = this.getCompoundSubtotal() - this.getDiscounts()
          if(after_discount <= 0){
            amount = 0
          }
          return this.getPercentage(amount, after_discount)
        } else {
          return this.getPercentage(amount, this.getCompoundSubtotal())
        }
      }

    },
    getPercentage(amount, subtotal){
      if(subtotal && subtotal.toString().includes(',')){
        subtotal = subtotal.replace(',', '.')
      }
      if(amount && amount.toString().includes(',')){
        amount = amount.replace(',', '.')
      }
      return parseFloat(subtotal) * (parseFloat(amount)*0.01)
    },
    getDiscounts(){
      let total_discount = 0
      for(let n in this.promo_codes){
        total_discount = parseFloat(total_discount) + parseFloat(this.promo_codes[n].value_amount)
      }
      return total_discount
    },
    getFormattedDate(format, d){
      return formattedDate(format, d)
    },
    getDayOfWeek(d){
      if(this.locale.show_day_of_week){
        return dayOfWeek(d)
      }
      return ''
    },
    async applyPromo(){
      let bookings = this.bookings
      let today = new Date()
      today = today.getFullYear() +  "-" +  ('0' + (today.getMonth() + 1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2);
      this.loading = true
      let code = this.bss.code
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/promos?code=' + this.promo_code +
        '&date=' + today + '&bookings=' + encodeURIComponent(JSON.stringify(bookings))

      this.axios.get(url)
        .then(response => {
          this.loading = false
          if(JSON.stringify(response.data.promo_code) === '{}'){
            swal({
              title: this.$t('payment_request.swals.error'),
              text: this.$t('payment_request.swals.invalidPromo'),
              type: 'error',
              confirmButtonColor: '#1d8cf8',
              confirmButtonText: 'OK'
            })
          } else {
            this.promo_code = ''
            let dupe = false
            for(let n in this.promo_codes){
              if(this.promo_codes[n].name === response.data.promo_code.name){
                dupe = true
              }
              if(this.promo_codes[n].is_combineable === 0 || response.data.promo_code.is_combineable === 0){
                swal(this.$t('payment_request.swals.error'), this.$t('payment_request.swals.nonCombineablePromo'), 'error')
                return
              }
            }
            if(!dupe){
              let value_amount = parseFloat(response.data.promo_code.value_amount)

              if(this.transaction.due < value_amount){
                value_amount = this.transaction.due
              }
              this.savePromo(response.data.promo_code, value_amount, 'promo')
            }
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })

    },
    async savePromo(promo, value_amount, type){
      let code = this.bss.code
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/promos'

      let data = {
        id: promo.id,
        transaction_id: this.transaction.id,
        value_amount: value_amount,
        type: type
      }

      this.axios.post(url, data)
        .then(response => {
          this.apply_promo = true
          this.promo_codes.push(
            {
              id: promo.id,
              name: promo.name,
              value_amount: value_amount,
              is_combineable: promo.is_combineable
            }
          )

          this.getTransaction()
          swal({
            title: this.$t('payment_request.swals.success'),
            text: this.$t('payment_request.swals.promoApplied'),
            type: 'success',
            showCancelButton: false
          })
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal({
              title: this.$t('payment_request.swals.error'),
              text: this.$t('payment_request.swals.invalidPromo'),
              type: 'error',
              showCancelButton: false
            })
          }
        })

    },
    getDate(d){
      return formattedDate(this.date_format, d)
    },
    getTime(d) {
      return time(this.time_format, d);
    },
    goHome(){
      window.location.href = this.bss.redirect_url
    },
    goToWaiver(){
      let code = this.bss.code
      let path = '/checkin/' + code
      this.$router.push(path)
    },
    updatePaymentAmount(option){
      this.payment_option = option
      if(this.payment_option === 'full'){
        this.partial_amount = this.transaction.due

        this.payment_option_title = this.$t('payment_request.payFullAmount') + ' - ' +
          this.getCurrencySymbol(this.locale.currency) + this.getAmount(this.transaction.due)+ this.getCurrencyCode(this.locale.currency)
      } else if(this.payment_option === 'deposit'){
        this.partial_amount = this.deposit
        this.payment_option_title = this.$t('payment_request.payDeposit') + ' - ' +
          this.getCurrencySymbol(this.locale.currency) + this.getAmount(this.deposit) + this.getCurrencyCode(this.locale.currency)
      } else {
        this.payment_option_title = this.$t('payment_request.payCustomAmount')
      }
      if (this.payment_settings.payment_gateway_id === 3) {
        this.initializeStripe()
      }
    },
    isEmailValid(emailString) {
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(emailString);
    },
    getCurrencyCode(code){
      if(this.locale.currency_format === 1){
        return code
      } else {
        return ''
      }
    },
    getCurrencySymbol(code){
      if(this.locale.currency_format === 0){
        let c = _.find(this.currencies, { code: code });
        return c.symbol
      } else {
        return ''
      }
    },
    getNumberFormat(value){
      if(this.locale.number_format === 1){
        value = value.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      }
      return value
    },
    getAmount(integer){
      if(integer == null){
        return
      }
      return this.getNumberFormat(parseFloat(integer).toFixed(2))
    },
    is_valid(){
      let errors = 0
      if(this.user.first_name == ''){
        this.errs.first_name = this.$t('payment_request.errors.firstName')
        errors++
      } else {
        this.errs.first_name = ''
      }

      if(this.user.last_name == ''){
        this.errs.last_name = this.$t('payment_request.errors.lastName')
        errors++
      } else {
        this.errs.last_name = ''
      }

      if(!this.isEmailValid(this.user.email)){
        this.errs.email = this.$t('payment_request.errors.email')
        errors++
      } else {
        this.errs.email = ''
      }

      if(this.user.phone == ''){
        this.errs.phone = this.$t('payment_request.errors.phone')
        errors++
      } else {
        this.errs.phone = ''
      }

      for(let n in this.custom_fields){
        if(this.custom_fields[n].required && this.isFieldVisibile(this.custom_fields[n]) && !this.custom_fields[n].customer_response){
          this.custom_fields[n].error = this.$t('payment_request.errors.required')
          errors++
        } else {
          this.custom_fields[n].error = ''
        }
      }

       if(errors > 0){
         return false
       }
       return true
    },
    is_valid_card(){
      let errors = 0
      if(this.payment.cardNumber == '' || this.payment.cardNumber.length < 19){
        this.errs.cnn = this.$t('payment_request.errors.cnn')
        errors++
      } else {
        this.errs.cnn = ''
      }

      if(this.payment.zip == ''){
        this.errs.zip = this.$t('payment_request.errors.zip')
        errors++
      } else {
        this.errs.zip = ''
      }

      if(this.payment.expirationDate == '' || this.payment.expirationDate.length < 5){
        this.errs.expiration = this.$t('payment_request.errors.expiration')
        errors++
      } else {
        this.errs.expiration = ''
      }

      if(this.payment.cardCode == '' || this.payment.cardCode < 3 ){
        this.errs.cvv = this.$t('payment_request.errors.cvv')
        errors++
      } else {
        this.errs.cvv = ''
      }
      if(errors > 0){
        return false
      }
      return true
    },
    is_amount_valid(){
      if(!this.require_deposit && parseFloat(this.transaction.due) < this.partial_amount){
        this.errs.amount = this.$t('payment_request.errors.amountMoreThanTransaction')
        return false
      } else if (this.require_deposit && parseFloat(this.deposit) < this.partial_amount){
        this.errs.amount = this.$t('payment_request.errors.amountMoreThanDeposit')
        return false
      }
      this.errs.amount = ''
      return true
    },
    async handlePaystackPayment(reference, data){
      let url = API_LOCATION + 'bookings/' + this.hash
      data.paystack_reference = reference

      this.loading = true
      axios.post(url, data)
        .then(response => {
          this.disable_submit = false
          this.loading = false
          swal({
            title: this.$t('payment_request.swals.success'),
            text: this.$t('payment_request.swals.paymentProcessed'),
            type: 'success',
            showCancelButton: false
          }).then((response)=> {
            this.is_paid_in_full = true
          })
        })
        .catch(err => {
          this.loading = false
          this.disable_submit = false
          console.log(err)
          swal({
            title: this.$t('payment_request.swals.transactionDeclined'),
            text: this.$t('payment_request.swals.checkInfo'),
            type: 'error'
          })
        })
    },
    async payWithPaystack(data) {
      let handler = PaystackPop.setup({
        key: this.payment_settings.api_name, // Replace with your public key
        email: this.user.email,
        amount: (parseFloat(data.total) * 100).toFixed(0),
        ref: ''+Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        callback: (response) => {
          this.handlePaystackPayment(response, data)
        }
      });

      handler.openIframe();
    },
    async payWithYappy(){
      if(!this.is_valid()){
        return
      }
      let url = API_LOCATION + 'yappy/payment-request?hash=' + this.hash

      let amount = this.transaction.due
      if(this.payment_option === 'partial'){
        if(!this.is_amount_valid()){
          return
        }
        amount = this.partial_amount
      } else if (this.payment_option === 'deposit'){
        amount = this.deposit
      }

      let custom_fields = []
      let resp = ''
      for(let n in this.custom_fields){
        resp = this.custom_fields[n].customer_response
        if(this.custom_fields[n].type === 3 && this.custom_fields[n].customer_response){
          if(this.custom_fields[n].customer_response.has_follow_up_field){
            resp = this.custom_fields[n].customer_response.name + ': ' + this.custom_fields[n].customer_response.follow_up_response
          } else {
            resp = this.custom_fields[n].customer_response.name
          }
        }
        custom_fields.push({
          customer_response: resp,
          id: this.custom_fields[n].id,
          type: this.custom_fields[n].type
        })
      }

      let data = {
        user: this.user,
        total: amount,
        currency: this.locale.currency,
        bookings: this.bookings,
        custom_fields: custom_fields,
        locale: this.$i18n.locale
      }

      this.loading = true
      axios.post(url, data)
        .then(response => {
          this.loading = false
          window.location.href = response.data.url
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    async createPayment(){
      if(!this.is_valid()){
        return
      }
      if(this.payment_settings.payment_gateway_id === 1){
        if(!this.is_valid_card()){
          return
        }
      }
      let url = API_LOCATION + 'bookings/' + this.hash

      let amount = this.transaction.due
      if(this.payment_option === 'partial'){
        if(!this.is_amount_valid()){
          return
        }
        amount = this.partial_amount
      } else if (this.payment_option === 'deposit'){
        amount = this.deposit
      }

      let custom_fields = []
      let resp = ''
      for(let n in this.custom_fields){
        resp = this.custom_fields[n].customer_response
        if(this.custom_fields[n].type === 3 && this.custom_fields[n].customer_response){
          if(this.custom_fields[n].customer_response.has_follow_up_field){
            resp = this.custom_fields[n].customer_response.name + ': ' + this.custom_fields[n].customer_response.follow_up_response
          } else {
            resp = this.custom_fields[n].customer_response.name
          }
        }
        custom_fields.push({
          customer_response: resp,
          id: this.custom_fields[n].id,
          type: this.custom_fields[n].type
        })
      }

      let data = {
        user: this.user,
        total: amount,
        currency: this.locale.currency,
        bookings: this.bookings,
        custom_fields: custom_fields,
        locale: this.$i18n.locale
      }

      if (this.selectedPaymentOption.name === 'Card' && this.payment_settings.payment_gateway_id === 1){
        this.payment.cardNumber = this.payment.cardNumber.replace(/\s/g, '');
        let payment = {
          cardNumber: this.payment.cardNumber,
          expirationDate: this.payment.expirationDate,
          amount: parseFloat(amount).toFixed(2),
          cardCode: this.payment.cardCode,
          zip: this.payment.zip,
          cardholder_first_name: this.payment.cardholder_first_name,
          cardholder_last_name: this.payment.cardholder_last_name
        }
        data.payment = payment
      } else if (this.selectedPaymentOption.name === 'Card' && this.payment_settings.payment_gateway_id === 2) {
        //Square
        this.loading = true
        this.disable_submit = true
        try {
          const token = await this.tokenize(this.card);
          const verificationToken = await this.verifyBuyer(this.square, token);
          let payment = {
            cardholder_first_name: this.user.first_name,
            cardholder_last_name: this.user.last_name,
            locationId: this.squareLocationId,
            sourceId: token,
            verificationToken: verificationToken
          }
          data.payment = payment
        } catch (error) {
          //Square
          this.loading = false
          this.disable_submit = false
        }
      } else if (this.selectedPaymentOption.name === 'Card' &&  this.payment_settings.payment_gateway_id === 3){

        let payment = {
          cardholder_first_name: this.user.first_name,
          cardholder_last_name: this.user.last_name
        }
        data.paymentIntent = this.payment_intent_id
        data.payment = payment
      }
      else if (this.selectedPaymentOption.name === 'Card' &&  this.payment_settings.payment_gateway_id === 4){
        let payment = {
          cardholder_first_name: this.user.first_name,
          cardholder_last_name: this.user.last_name
        }
        data.payment = payment
        let paystack = this.payWithPaystack(data)
        return
      } else if (this.selectedPaymentOption.name === 'Gift card'){
        if(this.gift_card_code === '' ){
          this.errs.gift_card_code = this.$t('payment_request.errors.giftCardCode')
          return
        } else {
          this.errs.gift_card_code = ''
        }
        data.gift_card_code = this.gift_card_code
      }
      this.loading = true
      this.disable_submit = true
      axios.post(url, data)
        .then(response => {
          if(this.selectedPaymentOption.name === 'Card' && this.payment_settings.payment_gateway_id === 3){
            //stripe confirm payment goes here
            let elements = this.elements
            let self = this
            this.stripe.confirmPayment({
              elements,
              redirect: 'if_required',
              confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: "https://offthecouch.io/",
              },
            }).then(function(result) {
              if (result.error) {

                // Inform the customer that there was an error.
                swal({
                  title: self.$t('payment_request.swals.transactionFailed'),
                  text: self.$t('payment_request.swals.cardDeclined'),
                  type: 'error',
                  showCancelButton: false,
                  confirmButtonColor: '#fd5d93',
                  confirmButtonText: self.$t('payment_request.swals.tryAgain')
                })
              } else {
                swal({
                  title: self.$t('payment_request.swals.success'),
                  text: self.$t('payment_request.swals.paymentProcessed'),
                  type: 'success',
                  showCancelButton: false
                }).then((response)=> {
                  self.is_paid_in_full = true
                })
              }
            });
          } else {
            swal({
              title: this.$t('payment_request.swals.success'),
              text:  this.$t('payment_request.swals.paymentProcessed'),
              type: 'success',
              showCancelButton: false
            }).then((response)=> {
              this.is_paid_in_full = true
            })
          }
          this.disable_submit = false
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.disable_submit = false
          console.log(err)
          swal({
            title:  this.$t('payment_request.swals.transactionDeclined'),
            text: this.$t('payment_request.swals.checkInfo'),
            type: 'error'
          })
        })
    },
    async getTransaction(){
      let url = API_LOCATION + 'bookings/' + this.hash
      axios.get(url)
        .then(response => {
          this.transaction = response.data.transaction
          this.bss = response.data.settings
          this.locale = response.data.locale
          this.payment_settings = response.data.payment_settings
          this.bookings = response.data.bookings
          this.partial_amount = this.transaction.due
          this.taxes = response.data.taxes
          this.fees = response.data.fees
          this.promo_codes = response.data.promos
          this.tips = response.data.tips
          this.items = response.data.items
          this.gift_cards = response.data.gift_cards
          this.deposit = response.data.deposit
          this.custom_fields = response.data.custom_fields
          this.custom_fields = _.orderBy(this.custom_fields, 'position')
          this.payment_methods = response.data.payment_methods
          for(let n in this.payment_methods){
            if(this.payment_methods[n].name === 'Card'){
              this.payment_methods[n].display_name = this.$t('payment_request.card')
            }
            else if(this.payment_methods[n].name === 'Gift card'){
              this.payment_methods[n].display_name = this.$tc('payment_request.giftCard', 1)
            }
            else {
              this.payment_methods[n].display_name = this.payment_methods[n].name
            }
          }

          this.selectedPaymentOption = this.payment_methods[0]

          for(let n in this.bookings){
            if(this.bookings[n].allow_deposit && this.bookings[n].require_deposit){
              this.require_deposit = true
              this.payment_option = 'deposit'
              this.partial_amount = this.deposit
            }
          }

          for(let n in this.custom_fields){
            if(this.custom_fields[n].external_links){
              this.custom_fields[n].external_links = JSON.parse(this.custom_fields[n].external_links)
            }

            if(this.custom_fields[n].policies){
              this.custom_fields[n].policies = JSON.parse(this.custom_fields[n].policies)
            }

            if(this.custom_fields[n].type === 3){
              this.custom_fields[n].dropdown_options = _.orderBy(JSON.parse(this.custom_fields[n].dropdown_options), 'id')
            }
          }

          if(!this.require_deposit && parseFloat(this.transaction.due) <= 0 && this.apply_promo == false){
            this.is_paid_in_full = true
          }
          else if(this.require_deposit && parseFloat(this.deposit) <= 0 && this.apply_promo == false){
            this.is_paid_in_full = true
          }
          else if(this.apply_promo == false){
            //Stripe
            if(this.payment_settings.payment_gateway_id === 3){
              this.loadStripe()
            }
            if(this.payment_settings.payment_gateway_id === 2){
              this.squareAppId = this.payment_settings.api_name
              this.squareLocationId = this.payment_settings.api_location
              this.loadSquare(this.payment_settings.is_live)
            }
            if(this.payment_settings.payment_gateway_id === 4){
              this.loadPaystack()
            }
          }
          //Locale
          this.country = _.find(this.countries, { code: this.locale.country })
          this.time_format = this.locale.time_format
          this.date_format = this.locale.date_format
          this.updatePaymentAmount('full')
        })
        .catch(err => {
          this.notFound = true
          console.log(err)
        })
    },
    async loadPaystack(){
      const script = document.createElement('script');
      script.src = 'https://js.paystack.co/v2/inline.js'
      script.async = true;
      document.body.appendChild(script);
    },
    async loadSquare(is_live){
      const script = document.createElement('script');
      if(is_live){
        script.src = 'https://web.squarecdn.com/v1/square.js'
      } else {
        script.src = 'https://sandbox.web.squarecdn.com/v1/square.js'
      }
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        this.initializeSquare()
      };
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        this.loading = false
        this.disable_submit = false
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
          )}`;
        }
        throw new Error(errorMessage);
      }
    },
    colorToHex(color) {
      if (color.startsWith('#')) {
        if (color.length === 4) {
          return `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
        }
        if (color.length === 7) {
          return color;
        }
      }

      const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/i);

      if (!match) {
        throw new Error("Invalid color format");
      }

      const r = parseInt(match[1]).toString(16).padStart(2, '0');
      const g = parseInt(match[2]).toString(16).padStart(2, '0');
      const b = parseInt(match[3]).toString(16).padStart(2, '0');

      return `#${r}${g}${b}`;
    },
    async initializeCard(payments) {
      const customStyle = {
        '.input-container.is-focus': {
          borderColor: '#e14eca',
        },
        '.input-container.is-error': {
          borderColor: '#ff1600',
        },
        '.message-icon': {
          color: this.colorToHex(this.bss.text_color_card),
        },
        '.message-text.is-error': {
          color: '#ff1600',
        },
        '.message-icon.is-error': {
          color: '#ff1600',
        },
        'input.is-error': {
          color: '#ff1600',
        },
        '@media screen and (max-width: 600px)': {
          'input': {
            'fontSize': '12px',
          }
        },
        '.input-container': {
          borderColor: this.colorToHex(this.bss.text_color_card),
          // backgroundColor: this.bss.card_color,
          // color: this.bss.text_color_card,
          // 'font-family': this.bss.font_family_card,
          borderRadius: '6px',
        },
        '.message-text': {
          color: this.colorToHex(this.bss.text_color_card),
          // 'font-family': this.bss.font_family_card
        },
        input: {
          backgroundColor: this.colorToHex(this.bss.card_color),
          color: this.colorToHex(this.bss.text_color_card),
          fontFamily: 'helvetica neue, sans-serif',
          //fontFamily: this.bss.font_family_card
        },
        'input::placeholder': {
          color: this.colorToHex(this.bss.text_color_card),
        },
      }
      const card = await payments.card({
        style: customStyle,
      });
      await card.attach('#card-container');
      return card;
    },
    async verifyBuyer(payments, token) {
      let amount = Math.abs(this.transaction.due)
      if(this.payment_option === 'partial'){
        amount = this.partial_amount
      }
      const verificationDetails = {
        amount: amount.toString(),
        /* collected from the buyer */
        billingContact: {
          familyName: this.user.first_name,
          givenName: this.user.last_name,
        },
        currencyCode: this.locale.currency,
        intent: 'CHARGE',
      };

      const verificationResults = await payments.verifyBuyer(
        token,
        verificationDetails
      );
      return verificationResults.token;
    },
    async initializeSquare(){
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }
      if(this.card){
        await this.card.destroy()
      }
      this.square = window.Square.payments(this.squareAppId, this.squareLocationId);
      this.square.setLocale(this.$i18n.locale)
      try {
        this.card = await this.initializeCard(this.square);
      } catch (e) {
        console.error('Initializing Card failed', e);
        return;
      }
    },
    async initializeStripe(){
      if (!window.Stripe) {
        throw new Error('Stripe.js failed to load properly');
      }
      this.disable_submit = true
      this.stripe = window.Stripe(this.payment_settings.api_name);
      let url = API_LOCATION + 'bookings/company-groups/' + this.bss.code + '/payment/stripe-payment-intent'

      let amount = Math.abs(this.transaction.due)
      if(this.payment_option === 'partial'){
        amount = this.partial_amount
      } else if (this.payment_option === 'deposit'){
        amount = this.deposit
      }

      let data = {
        currency: this.locale.currency,
        total: amount,
      }

      axios.post(url, data)
        .then(response => {
          this.loading = false
          let clientSecret = response.data.clientSecret
          this.payment_intent_id = response.data.clientSecret.split('_')[0] + '_' + response.data.clientSecret.split('_')[1]
          const appearance = {
            theme: 'stripe',
            variables: {
              colorPrimary: this.colorToHex(this.bss.button_color),
              colorBackground: this.colorToHex(this.bss.card_color),
              colorText: this.colorToHex(this.bss.text_color_card),
            },
          };
          this.elements = this.stripe.elements({ appearance, clientSecret });
          const paymentElementOptions = {
            layout: "tabs",
          };

          const paymentElement = this.elements.create("payment", paymentElementOptions);
          paymentElement.mount("#payment-element");
          paymentElement.on('change', (event) => {
            if (event.complete) {
              // Enable the payment button
              this.disable_submit = false;
            } else {
              // Disable the payment button
              this.disable_submit = true;
            }
          });
        })
        .catch(err => {
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    async loadStripe(){
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.async = true;
      let self = this
      script.onload = function() {
        self.initializeStripe()
      };
      document.body.appendChild(script);
      // setTimeout(() => {
      //   this.initializeStripe()
      // }, 400)
    },
  },
  mounted() {
    this.hash = this.$route.params.hash
    this.getTransaction()
  }
}
</script>

<style scoped>
img {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  float: left;
  background-size: cover;
  background-position: center center;
  margin-top: -7px;
  margin-bottom: -7px;
}
</style>
