<template>
<div>
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <!--   No data panel -->
  <div v-if="empty==true" class="mt-3" >
    <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
      <h4 class="mt-3">No data available.</h4>
    </div>
  </div>
  <!--  Booking items-->
  <div class="row ml-5 mr-5">
    <div class="col-lg-4 col-md-4 col-sm-6 col-10" v-for="game in games" :key="game.id">
      <card>
        <img slot="image" class="card-img-top fixed-size-img" :src="game.img"  alt="Card image cap"/>
        <h4 class="card-title">{{game.name}}</h4>
        <p v-show="game.min_players_count != null">Players: &nbsp; &nbsp;{{game.min_players_count}}
          <span v-show="game.max_players_count != null"> - </span> {{game.max_players_count}}</p>
        <p v-show="game.duration_minutes != null">Duration: &nbsp; &nbsp;{{game.duration_minutes}} minutes</p>
<!--        <p v-show="game.difficulty_level != null" class="d-inline-flex">Difficulty: &nbsp; &nbsp;-->
<!--          <el-rate disabled v-model="game.difficulty_level"></el-rate>-->
<!--        </p>-->
        <div class="row justify-content-center">
          <base-button class="button-wide" v-on:click="showBooking(game)" type="warning" simple >Book Now</base-button>
        </div>
      </card>
    </div>
  </div>
</div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {ampm} from "@/plugins/dateFormatter";

export default {
  name: "SelectGame",
  data() {
    return {
      loading: false,
      empty: false,
      games: ''
    }
  },
  methods: {
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getSchedules(){
      let code = this.$route.params.company
      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'bookings/company-groups/' + code

      axios.get(url, config)
        .then(response => {
          this.loading = false
          //refresh the page
          axios.get(url, config)
            .then(response => {
              this.games = response.data.schedules
              if(this.games.length <= 0 ){
                this.empty = true
              }
            })

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })

    },
    showBooking(game){
      this.is_selected_game = true
      this.selectedGame = game
      this.number_of_players = this.selectedGame.min_players_count
      this.subtotal = parseFloat(this.selectedGame.price) * parseInt(this.number_of_players)
      this.associateSlots()
    },
  },
  mounted() {
    this.getSchedules()
  }
}
</script>

<style scoped>

</style>
