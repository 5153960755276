<template>
<div :style="{'overflow-x': 'hidden'}">
  <!--    Loader-->
  <loading-panel :loading="loading"></loading-panel>
  <!--   No data panel -->
  <div v-if="empty==true" class="mt-3" >
    <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
      <h4 class="mt-3">No data available.</h4>
    </div>
  </div>
  <!--  Purchases-->
  <h3 v-if="purchases.length > 0">Purchases</h3>
  <div class="row">
    <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="p in purchases" :key="p.id">
      <card >
        <div slot="image" class="card-img-top" style="padding-bottom: 0; height: 200px">
          <el-carousel :arrow="getArrowSetting(JSON.parse(p.game_images))" :autoplay="false" indicator-position="none" :interval="5000">
            <el-carousel-item style="border-radius: 5px; width: 100%; height: 200px;"
                              :style="{'background-color': item.background_color}" v-for="(item, index) in JSON.parse(p.game_images)"
                              :key="'CS' + index">
              <div  style="border-radius: 5px; width: 100%; height: 200px; position: relative">
                <img
                  :style="{'transform': 'translate(0%, 0%) scale(' + item.scale*0.01 + ')', 'left': item.x + 'px', 'top': item.y + 'px', 'object-fit':  item.object_fit, 'height': '100%'}"
                  class="card-img"
                  style="position: absolute; transform-origin: center center; will-change: transform"
                  slot="image" :src="item.url"  alt="Card image cap"/>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <h4 class="card-title">
          <span class="text-success">{{p.game_name}}</span> at {{p.company_name}}
        </h4>

        <p class="text-white"><i class="text-success tim-icons icon-calendar-60"></i>
         &nbsp;{{getDate(p.booking_date)}}
          &nbsp;<i class="text-success tim-icons icon-time-alarm"></i>
          &nbsp;{{getAMPM(p.start_time)}}
          &nbsp;
        </p>
        <p class="text-white"><i class="text-success tim-icons icon-square-pin"></i>
          &nbsp;{{p.address_line_1}} {{p.address_line_2}} {{p.city}} {{p.state}} {{p.zip}}
        </p>
        <p class="text-white">
          Check-in link: <a :href="getLink(p.code)">{{getLink(p.code)}}</a>

        </p>
      </card>
    </div>
  </div>
  <!--  Checkins-->
  <h3 v-if="check_ins.length > 0">Completed check-ins</h3>
  <div class="row">
    <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="c in check_ins" :key="c.id">
      <card >
        <img slot="image" class="card-img-top fixed-size-img" :src="c.img"  alt="Card image cap"/>
        <h4 class="card-title">
          <span class="text-success">{{c.game_name}}</span> at {{c.company_name}}</h4>
        <p class="text-white"><i class="text-success tim-icons icon-calendar-60"></i>
          &nbsp;{{getDate(c.booking_date)}}
          &nbsp;<i class="text-success tim-icons icon-time-alarm"></i>
          &nbsp;{{getAMPM(c.start_time)}}
        </p>
        <p class="text-white"><i class="text-success tim-icons icon-square-pin"></i>
          &nbsp;{{c.address_line_1}} {{c.address_line_2}} {{c.city}} {{c.state}} {{c.zip}}
        </p>
      </card>
    </div>
  </div>

</div>
</template>

<script>
import {date, time, ampm} from "@/plugins/dateFormatter";
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Carousel, CarouselItem} from "element-ui";

export default {
  name: "FutureGames",
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },
  data() {
    return {
      loading: false,
      empty: false,
      purchases: [],
      check_ins: [],
    }
  },
  methods: {
    getArrowSetting(images){
      if(!images){
        return ''
      }
      if(images.length <= 1){
        return 'never'
      }
      return "hover"
    },
    getDate(d) {
      return date(d)
    },
    getTime(d) {
      return time(d)
    },
    getAMPM(d) {
      return ampm(d);
    },
    getLink(code){
      return 'https://offthecouch.io/checkin/' + code
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    async getFutureGames() {
      let today = new Date()
      let t = today.getFullYear() +  "-" +  ('0' + (today.getMonth() + 1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2);

      this.loading = true
      let token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'games/upcoming?t=' + t

      axios.get(url, config)
        .then(response => {
          this.loading = false
          //refresh the page
          axios.get(url, config)
            .then(response => {
              this.purchases = response.data.transactions
              this.check_ins = response.data.check_ins
              if (this.purchases.length <= 0 && this.check_ins.length <= 0) {
                this.empty = true
              }

            })

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if (err.response.status == 401) {
            this.$router.push('/forbidden')
          }
        })
    },
  },
  mounted() {
    this.getFutureGames()
  }
}
</script>

<style scoped>

</style>
