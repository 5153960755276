<template>
  <div>
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto text-center">
        <h1 class="title">{{game}} Leaderboard</h1>
      </div>
    </div>
    <base-button
      @click.native="viewDetails()"
      type="info"
      size="sm"
      simple
    >My game details
    </base-button>
    <!--Medium and large screens-->
    <div class="row mt-3 d-none d-sm-block">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <card card-body-classes="table-full-width">
          <div class="mt-3" v-if="noDataAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">Leaderboard not available.</h4>
            </div>
          </div>
          <div v-if="noDataAvailable === false" class="row table-content">
            <div  class="col-md-12 col-lg-12 col-sm-12 col-12">
              <b-table :tbody-tr-class="rowClass" striped hover :items="leaderboard" :fields="fields">
                <template v-slot:cell(rank)="row">
                  <i v-if="row.index == 0" class="tim-icons icon-trophy gold"></i>
                  <i v-if="row.index == 1" class="tim-icons icon-trophy silver"></i>
                  <i v-if="row.index == 2" class="tim-icons icon-trophy bronze"></i>

                  <span v-if="row.index > 2">{{row.item.row_rank}}</span>
                </template>
                <template v-slot:cell(completion_time)="row">
                  <span >{{getTime(row.item.completion_time)}} min</span>
                </template>
                <template v-slot:cell(number_of_players)="row">
                  <p>{{row.item.players_count}} </p>
                </template>
                <template v-slot:cell(number_of_hints)="row">
                  <p>{{row.item.number_of_clues}} </p>
                </template>
              </b-table>
            </div>
          </div>
        </card>
      </div>
    </div>
    <!--Mobile-->
    <div class="row mt-3 d-block d-sm-none">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-for="(person, index) in leaderboard" :key="index">
        <card :style="cardStyle(person)" class="card-stats">
          <div class="mt-3" v-if="noDataAvailable === true">
            <div class="font-icon-detail">
              <span style="font-size: 3em">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
              <h4 class="mt-3">Leaderboard available.</h4>
            </div>
          </div>
          <div v-if="noDataAvailable === false" class="row">
            <div class="col-3">
              <div class="info-icon icon-warning text-center">
                <a href=""
                   v-on:click="viewDetails(person)"
                >
                  <span class="mt-5 text-white rank-text">{{index+1}}</span>
                </a>
              </div>
            </div>
            <div class="col-9">
              <div class="numbers">
                <slot>
                  <p :style="pStyle(person)" class="card-category">
                    <i v-if="index == 0" class="tim-icons icon-trophy gold mr-1"></i>
                    <i v-if="index == 1" class="tim-icons icon-trophy silver mr-1"></i>
                    <i v-if="index == 2" class="tim-icons icon-trophy bronze mr-1"></i>

                    {{person.team_name}}
                  </p>
                  <p :style="pStyle(person)" class="mt-2">
                    <span v-if="fields.includes('completion_time')"><i class="tim-icons icon-time-alarm mr-1 text-warning"></i>{{getTime(person.completion_time)}} min &nbsp;</span>
                    <span v-if="fields.includes('number_of_hints')"><i class="tim-icons icon-puzzle-10 mr-1 text-warning"></i>{{person.number_of_clues}} hints &nbsp;</span>
                    <span v-if="fields.includes('number_of_players')"><i class="tim-icons el-icon-user mr-1 text-warning"></i>{{person.players_count}} players</span>
                  </p>
                </slot>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import { BTable } from 'bootstrap-vue'


export default {
  name: "Leaderboard",
  components: {
    BTable
  },
  data() {
    return {
      leaderboard: [],
      show_players: false,
      game: '',
      noDataAvailable: false,
      settings: '',
      loading: false,
      fields: ['rank', 'team_name'],
    }
  },
  methods: {
    pStyle(item){
      if(item.m === 'match'){
        return {'color': '#2d2a2a'}
      }
    },
    cardStyle(item){
      if(item.m === 'match'){
        return {'background-color': '#2bffc6'}
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.m === 'match') return 'table-success'
    },
    getTime(time){
      if(time == undefined){
        return
      }
      let hours = time.split(':')[0]
      let minutes = time.split(':')[1]
      return parseInt(parseInt(hours)*60 + parseInt(minutes))
    },
    getAuthToken() {
      return new Promise ( function(resolve) {
        Auth.currentSession()
          .then(data =>{
            resolve(data.idToken.jwtToken)
          })
          .catch(err => console.log(err))
      })
    },
    viewDetails(){
      this.$router.push('/game-details')
    },
    async getLeaderboard () {
      this.loading = true
      var token = await this.getAuthToken()
      let config = {headers: {Authorization: token}}
      let url = API_LOCATION + 'games/' + JSON.parse(localStorage.getItem('gameInstanceId'))
        + '/leaderboard?game_id='+ JSON.parse(localStorage.getItem('gameId'))
      axios.get(url, config)
        .then(response => {
          for(let n in response.data.leaderboard){
            if(response.data.leaderboard[n].row_rank <= response.data.row_limit
            || response.data.leaderboard[n].m === 'match'){
              this.leaderboard.push(response.data.leaderboard[n])
            }
          }
          if(this.leaderboard.length <= 0){
            this.noDataAvailable = true
          } else {
            if(this.leaderboard[0].hasOwnProperty('players_count')){
              this.fields.push('number_of_players')
            }
            if(this.leaderboard[0].hasOwnProperty('number_of_clues')){
              this.fields.push('number_of_hints')
            }
            if(this.leaderboard[0].hasOwnProperty('completion_time')){
              this.fields.push('completion_time')
            }
          }
          this.game = JSON.parse(localStorage.getItem('gameName'))
          this.loading = false

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },

  },
  mounted () {
    this.getLeaderboard()
  }
}
</script>

<style scoped>
.gold {
  color: gold;
}
.silver {
  color: silver;
}
.bronze {
  color: darkorange;
}
.rank-text {
  font-size: xx-large;
}
</style>
