import i18n from 'src/i18n.js';

/**
 * Takes YYYY-MM-DDTHH:MM:SS.000Z and returns MM-DD-YYYY
 */
function getDate(d){
  let dd = d.split('T')[0]
  let date = dd.split('-')
  return date[1] + '-' + date[2] + '-' + date[0]
}

function getDayOfWeek(d) {
  if(d == undefined){
    return
  }
  let days = i18n.t('dates.days')
  let dt = new Date()
  let date = ''
  if(d.length > 10){
    let dd = d.split('T')[0]
    date = dd.split('-')
  } else {
    date = d.split('-')
  }

  dt.setFullYear(date[0])
  dt.setMonth(parseInt(date[1] - 1), date[2])

  return days[dt.getDay()]
}

function getFormattedDate(format, d){
  if(d == undefined){
    return
  }
  format = format.replaceAll('y', 'Y')
  format = format.replaceAll('d', 'D')
  let date = ''
  if(d.length > 10){
    let dd = d.split('T')[0]
    date = dd.split('-')
  } else {
    date = d.split('-')
  }

  if(format === 'MM/DD/YYYY'){
    return ('0' + date[1]).slice(-2)  + '/' +  ('0' + date[2]).slice(-2)  + '/' + date[0]
  } else if (format === 'DD/MM/YYYY'){
    return ('0' + date[2]).slice(-2)  + '/' +  ('0' + date[1]).slice(-2)  + '/' + date[0]
  } else if (format === 'YYYY/MM/DD'){
    return date[0] + '/' +  ('0' + date[1]).slice(-2)  + '/' + ('0' + date[2]).slice(-2)
  } else if (format === 'DD MMM YYYY'){
    let m = i18n.t('dates.shortMonths')
    return ('0' + date[2]).slice(-2)  + ' ' +  m[date[1]-1]  + ' ' + date[0]
  } else if (format === 'MMMM DD, YYYY'){
    let months = i18n.t('dates.months')
    return date[2] + ' ' + months[date[1]-1] + ' ' + date[0]
  }
}


/**
 * Takes MM-DD-YYYY and returns YYYY-MM-DD
 */
function setDate(d) {
  console.log('before reversal')
  console.log(d)
  let date = d.split('-')
  return date[2] + '-' + date[0] + '-' + date[1]
}

function get12HrTime(time){
  let hour = time.split(':')[0]
  let minute = time.split(':')[1]
  let dd = 'AM'

  if (hour >= 12) {
    hour = hour - 12;
    dd = 'PM';
    if (hour < 10) {
      hour = '0' + hour
    }
  }
  if (hour == 0) {
    hour = 12;
  }
  return ('0' + hour).slice(-2) + ':' + ('0' + minute).slice(-2) + ' ' + dd
}

function get24HrTime(time){
  let hour = time.split(':')[0]
  let minute = time.split(':')[1]

  return hour + ':' + ('0' + minute).slice(-2)
}
/**
 * Takes YYYY-MM-DDTHH:MM:SS.000Z and returns HH-MM AM/PM
 */
function getTime(format, d){
  if(d == undefined){
    return
  }
  let t = ''
  let time = ''

  if(d.includes('T')){
    t = d.split('T')[1]
  } else {
    t = d
  }

  if(format === 'HH:mm'){
    time = get24HrTime(t)
  } else {
    time = get12HrTime(t)
  }

  return time
}

function getAmPm(time){
  if(time == undefined){
    return
  }
  let hour = time.split(':')[0]
  let minute = time.split(':')[1]
  let dd = 'AM'

  if (hour >= 12) {
    hour = hour - 12;
    dd = 'PM';
    if (hour < 10) {
      hour = '0' + hour
    }
  }
  if (hour == 0) {
    hour = 12;
  }
  return ('0' + hour).slice(-2) + ':' + ('0' + minute).slice(-2) + ' ' + dd
}

export {getDayOfWeek as dayOfWeek, getDate as date, getAmPm as ampm, getTime as time, setDate as reverseDate, getFormattedDate as formattedDate}
