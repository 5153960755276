/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_J5zpiaMKG",
    "aws_user_pools_web_client_id": "1hcffv54c52gg6kg01akop9rfa",
    "oauth": {
        "domain": "questgenie.auth.us-east-1.amazoncognito.com"
    }
};


export default awsmobile;
