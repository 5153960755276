import BaseInput from 'src/components/Inputs/BaseInput.vue';
import BaseDropdown from 'src/components/BaseDropdown.vue';
import Card from 'src/components/Cards/Card.vue';
import BaseButton from 'src/components/BaseButton.vue';
import BaseCheckbox from 'src/components/Inputs/BaseCheckbox.vue';
import BaseTable from "@/components/BaseTable";
import LoadingPanel from 'src/components/LoadingPanel'
import Modal from 'src/components/Modal'
import { Input, InputNumber, Tooltip, Popover, Select, Option, DatePicker } from 'element-ui';
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(Card.name, Card);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseTable.name, BaseTable);
    Vue.component(LoadingPanel.name, LoadingPanel);
    Vue.component(Modal.name, Modal);
    Vue.component(Input.name, Input);
    Vue.component(InputNumber.name, InputNumber);
    Vue.use(Tooltip);
    Vue.use(Popover);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(DatePicker);
  }
};

export default GlobalComponents;
