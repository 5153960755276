<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline">
        <sidebar-toggle-button />
      </div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand">{{ routeName }}</a>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
<!--      &lt;!&ndash;<div class="search-bar input-group" @click="searchModalVisible = true">&ndash;&gt;-->
<!--        &lt;!&ndash;-->
<!--          <input type="text" class="form-control" placeholder="Search...">-->
<!--          <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div>-->
<!--        &ndash;&gt;-->
<!--        &lt;!&ndash;<button&ndash;&gt;-->
<!--          &lt;!&ndash;class="btn btn-link"&ndash;&gt;-->
<!--          &lt;!&ndash;id="search-button"&ndash;&gt;-->
<!--          &lt;!&ndash;data-toggle="modal"&ndash;&gt;-->
<!--          &lt;!&ndash;data-target="#searchModal"&ndash;&gt;-->
<!--        &lt;!&ndash;&gt;&ndash;&gt;-->
<!--          &lt;!&ndash;<i class="tim-icons icon-zoom-split"></i>&ndash;&gt;-->
<!--        &lt;!&ndash;</button>&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash; You can choose types of search input &ndash;&gt;&ndash;&gt;-->
<!--      &lt;!&ndash;</div>&ndash;&gt;-->
<!--      &lt;!&ndash;<modal&ndash;&gt;-->
<!--        &lt;!&ndash;:show.sync="searchModalVisible"&ndash;&gt;-->
<!--        &lt;!&ndash;class="modal-search"&ndash;&gt;-->
<!--        &lt;!&ndash;id="searchModal"&ndash;&gt;-->
<!--        &lt;!&ndash;:centered="false"&ndash;&gt;-->
<!--        &lt;!&ndash;:show-close="true"&ndash;&gt;-->
<!--      &lt;!&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;<input&ndash;&gt;-->
<!--          &lt;!&ndash;slot="header"&ndash;&gt;-->
<!--          &lt;!&ndash;v-model="searchQuery"&ndash;&gt;-->
<!--          &lt;!&ndash;type="text"&ndash;&gt;-->
<!--          &lt;!&ndash;class="form-control"&ndash;&gt;-->
<!--          &lt;!&ndash;id="inlineFormInputGroup"&ndash;&gt;-->
<!--          &lt;!&ndash;placeholder="SEARCH"&ndash;&gt;-->
<!--        &lt;!&ndash;/>&ndash;&gt;-->
<!--      &lt;!&ndash;</modal>&ndash;&gt;-->
<!--      <base-dropdown-->
<!--        tag="li"-->
<!--        :menu-on-right="!$rtl.isRTL"-->
<!--        title-tag="a"-->
<!--        title-classes="nav-link"-->
<!--        class="nav-item"-->
<!--      >-->
<!--        <template-->
<!--          slot="title"-->
<!--        >-->
<!--          <div class="notification d-none d-lg-block d-xl-block"></div>-->
<!--          <i class="tim-icons icon-sound-wave"></i>-->
<!--          <p class="d-lg-none">New Notifications</p>-->
<!--        </template>-->
<!--        <li class="nav-link">-->
<!--          <a href="#" class="nav-item dropdown-item"-->
<!--            >Mike John responded to your email</a-->
<!--          >-->
<!--        </li>-->
<!--        <li class="nav-link">-->
<!--          <a href="#" class="nav-item dropdown-item">You have 5 more tasks</a>-->
<!--        </li>-->
<!--        <li class="nav-link">-->
<!--          <a href="#" class="nav-item dropdown-item"-->
<!--            >Your friend Michael is in town</a-->
<!--          >-->
<!--        </li>-->
<!--        <li class="nav-link">-->
<!--          <a href="#" class="nav-item dropdown-item">Another notification</a>-->
<!--        </li>-->
<!--        <li class="nav-link">-->
<!--          <a href="#" class="nav-item dropdown-item">Another one</a>-->
<!--        </li>-->
<!--      </base-dropdown>-->
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template
          slot="title"
        >
          <div class="photo"><img :src="getImage()" /></div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p v-on:click="logout()" class="d-lg-none">Log out</p>
        </template>
        <li class="nav-link" v-if="!logging_out">
          <a href="" v-on:click="navigateTo('/settings/profile')" class="nav-item dropdown-item">Profile</a>
        </li>
        <div class="dropdown-divider" v-if="!logging_out"></div>
        <li class="nav-link" v-if="!logging_out">
          <a v-on:click="logout()"  class="nav-item dropdown-item">Log out</a>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'

export default {
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      logging_out: false
    };
  },
  methods: {
    navigateTo(location){
      this.$router.push(location)
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getImage(){
      // return JSON.parse(localStorage.getItem('profile')).img
      return 'test'
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    async logout () {
      this.logging_out = true
      await Auth.signOut()
        .then(data => console.log('data' + data))
        .catch(err => console.log('err' + err));
      console.log('logging out')
      localStorage.clear()
      AmplifyEventBus.$emit('authState', 'signedOut')
      this.$router.push('/login')
    }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
