<template>
<div class="con">
  <div class="row justify-content-center">
    <div class="col-lg-3 col-md-7 col-sm-10 col-10">
      <card >
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <base-button simple type="primary"
                         class="game-button" v-on:click="newUser()">
              <span class="ml-1 mt-1">New User</span>
            </base-button>
          </div>
        </div>
      </card>
    </div>
    <div class="col-lg-3 col-md-7 col-sm-10 col-10">
      <card >
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <base-button simple type="primary"
                         class="game-button" v-on:click="existingUser()">
              <span class="ml-1 mt-1">Existing User</span>
            </base-button>
          </div>
        </div>
      </card>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "NewOrExistingUser",
  methods: {
    newUser(){
      this.$router.push('register')
    },
    existingUser(){
      this.$router.push('login')
    }
  }
}
</script>

<style scoped>

.con{
  margin-top: 25%;
}

</style>
