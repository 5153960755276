import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Authentication/AuthLayout.vue';
import WaiverLayout from "@/pages/Layout/WaiverLayout";
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';
import Unauthorized from 'src/pages/GeneralViews/UnauthorizedPage'

//Authentication
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Authentication/Login.vue');
const Register = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Authentication/Register.vue');
import newOrExistingUser from 'src/pages/Authentication/NewOrExistingUser'

// Games
import PastGames from 'src/pages/Games/PastGames'
import PastGameDetails from "@/pages/Games/PastGameDetails";
import Leaderboard from "@/pages/Leaderboards/Leaderboard";


//Settings
import MyProfile from 'src/pages/Settings/MyProfile'



// Checkin
import Checkin from 'src/pages/Checkin/Checkin'
import SelectCompany from "@/pages/Checkin/SelectCompany";
import FutureGames from "@/pages/Checkin/FutureGames";

//Photos
import MyPhotos from "@/pages/Photos/MyPhotos";

//Bookings
import CreateBooking from "@/pages/Bookings/CreateBooking";
import PaymentPage from "@/pages/Bookings/PaymentPage";
import ShareableWaiver from "@/pages/Checkin/ShareableWaiver";
import Survey from "@/pages/Surveys/Survey";
import BookingReceipt from "@/pages/Bookings/BookingReceipt";

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    }
  ]
};

let gamesMenu = {
  path: '/games',
  component: DashboardLayout,
  redirect: '/past-games',
  name: 'Games',
  meta: { requiresAuth: true },
  children: [
    {
      path: '/past-games',
      name: 'Past Games',
      component: PastGames,
    },
    {
      path: '/game-details',
      name: 'Game Details',
      component: PastGameDetails
    },
    {
      path: '/leaderboard',
      name: 'Leaderboard',
      component: Leaderboard,
    },
  ]
};

let checkinMenu = {
  path: '/checkin',
  component: DashboardLayout,
  redirect: '/checkin',
  name: 'Waiver',
  meta: { requiresAuth: true },
  children: [
    {
      path: '/checkin',
      name: 'Check In',
      component: SelectCompany,
    },
    {
      path: '/upcoming-games',
      name: 'Upcoming Games',
      component: FutureGames,
    },

  ]
};

let photosMenu = {
  path: '/photos',
  component: DashboardLayout,
  redirect: '/photos',
  name: 'Photos',
  meta: { requiresAuth: true },
  children: [
    {
      path: '/photos',
      name: 'My Photos',
      component: MyPhotos,
    },


  ]
};



let settingsMenu = {
  path: '/settings',
  component: DashboardLayout,
  redirect: '/settings/profile',
  name: 'Settings',
  meta: { requiresAuth: true },
  children: [
    {
      path: 'profile',
      name: 'My Profile',
      components: { default: MyProfile }
    }
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/settings/profile',
    name: 'Home',
    meta: { requiresAuth: true }
  },
  {
    path: '/checkin/:company',
    name: 'Checkin',
    component: Checkin
  },
  {
    path: '/checkin/:company/kiosk',
    name: 'Checkin-kiosk',
    component: Checkin
  },
  {
    path: '/waiver/:hash',
    name: 'Shareable Waiver',
    component: ShareableWaiver
  },
  {
    path: '/returning',
    name: 'New or Existing User',
    component: newOrExistingUser
  },
  {
    path: '/book/:company',
    name: 'Bookings',
    component: CreateBooking
  },
  {
    path: '/book/:company/:event',
    name: 'Bookings',
    component: CreateBooking
  },
  {
    path: '/receipt',
    name: 'Bookings',
    component: BookingReceipt
  },
  {
    path: '/pay/:hash',
    name: 'Payment',
    component: PaymentPage
  },
  {
    path: '/surveys/:hash',
    name: 'Survey',
    component: Survey
  },

  // dashboardMenu,
  gamesMenu,
  settingsMenu,
  photosMenu,
  checkinMenu,
  authPages,
  { path: '/forbidden', component: Unauthorized },
  { path: '*', component: NotFound }
];

export default routes;
