<template>
  <div class="form-check" :class="[{ disabled: disabled }, inlineClass]">
    <label :for="cbId" class="form-check-label">
      <input
        :id="cbId"
        class="form-check-input"
        type="checkbox"
        :disabled="disabled"
        v-model="model"
      />
      <span class="form-check-sign" :style="{'background-color': accentColor}"></span>
      <slot> <span v-if="inline">&nbsp;</span> </slot>
    </label>
  </div>
</template>
<script>
export default {
  name: 'base-checkbox',
  model: {
    prop: 'checked'
  },
  props: {
    checked: [Array, Boolean],
    disabled: Boolean,
    inline: Boolean,
    hasError: Boolean,
    accentColor: {
      type: String,
      default: '#e14eca'
    },
    checkMarkColor: {
      type: String,
      default: '#232f3e'
    },
    borderColor: {
      type: String,
      default: '#e14eca'
    },
  },
  data() {
    return {
      cbId: '',
      touched: false
    };
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit('input', check);
      }
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
    }
  },
  methods: {
    updateCSSVariable() {
      // Update CSS variables
      document.documentElement.style.setProperty('--checkmark-color', this.checkMarkColor);
      document.documentElement.style.setProperty('--border-color', this.borderColor);
    }
  },
  created() {
    this.cbId = Math.random()
      .toString(16)
      .slice(2);
  },
  mounted() {
    this.updateCSSVariable();
  },
};
</script>
<style scoped>
.form-check .form-check-sign::before,
.form-check-sign::after{
  border: 1px solid var(--border-color);
  color: var(--checkmark-color) !important;
}

</style>
